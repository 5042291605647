import React from 'react';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';

import { Card } from '@mui/material';

import UserForm from './components/user-form';

import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/routes';

// ----------------------------------------------------------------------

interface EditUserProps {
}

// ----------------------------------------------------------------------

const EditUser: React.FC<EditUserProps> = () => {

  const navigate = useNavigate(); // Hook for navigation

  const handleCloseForm = () =>{
    navigate(routes.users);
  }

    return(
        <div>
            {/* Bread Crumb */}
            <div className='flex items-center justify-between pb-3 h-12'>
              {/* <h2>Staff / User / Edit</h2> */}
              <CustomBreadcrumbs
                // heading="Staff"
                links={[
                  { name: 'Staff', href: routes.users },
                  { name: 'User', href: routes.users },
                  { name: 'Edit' },
                ]}
              />
            </div>
            {/* User Form */}
            <div className='flex flex-row min-h-96'>
                <h2 className='uppercase font-bold w-1/5'>Info</h2>
                <Card className='w-4/5 p-5'>
                  <UserForm onClose={handleCloseForm} />
                </Card>
            </div>
        </div>
    )
};

export default EditUser;