// ActivityIcon.tsx

import React from 'react';

interface ActivityIconProps {
  fillColor?: string;
  strokeColor?: string;
}

const ActivityIcon: React.FC<ActivityIconProps> = ({ fillColor = '#FF922E',strokeColor = '#637381' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1856_4907" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <path d="M19 2.5C19 1.39543 18.1046 0.5 17 0.5H3C1.89543 0.5 1 1.39543 1 2.5C1 3.60457 1.89543 4.5 3 4.5H17C18.1046 4.5 19 3.60457 19 2.5ZM19 10C19 8.89543 18.1046 8 17 8H3C1.89543 8 1 8.89543 1 10C1 11.1046 1.89543 12 3 12H17C18.1046 12 19 11.1046 19 10ZM19 17.5C19 16.3954 18.1046 15.5 17 15.5H3C1.89543 15.5 1 16.3954 1 17.5C1 18.6046 1.89543 19.5 3 19.5H17C18.1046 19.5 19 18.6046 19 17.5Z" 
          fill={fillColor} stroke="white" strokeLinejoin="round"/>
      </mask>
      <g mask="url(#mask0_1856_4907)">
        <path d="M-2 1C-2 -0.656854 -0.656854 -2 1 -2H19C20.6569 -2 22 -0.656854 22 1V19C22 20.6569 20.6569 22 19 22H1C-0.656854 22 -2 20.6569 -2 19V1Z" 
          fill={strokeColor}/>
      </g>
    </svg>
  );
};

export default ActivityIcon;
