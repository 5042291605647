import React, { useState } from 'react';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import { useBoolean } from '../../../hooks/use-boolean';

import { Alert, IconButton, InputAdornment, Stack } from '@mui/material';

import axios from 'axios';

import FormProvider, {
  RHFRoundedTextField,
} from '../../../components/minimal/hook-form';
import Iconify from '../../../components/minimal/iconify';
import LoadingButton from '@mui/lab/LoadingButton';
// import { setStorage } from '../../../hooks/use-local-storage';

import noodyIMG from '../../../assets/images/noody.svg';
import loginIMG from '../../../assets/images/login.svg';
import noIMG from '../../../assets/images/no.svg'
import odyIMG from '../../../assets/images/ody.svg'

import { HOST_API } from '../../../config-global';
import { setStorage } from '../../../hooks/use-local-storage';

import { useAuth } from '../../../hooks/useAuth';
import { setAuthenticatedStatus } from '../../../interceptors/auth';
import { Link } from 'react-router-dom';

interface LoginFormProps {
}

const LoginForm: React.FC<LoginFormProps> = () => {
  // use global Authenticated State to check its current value or to change its value
  const { setAuthenticated } = useAuth();
  // console.log('Authenticated: ', isAuthenticated);

  // const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  const [errorMsg, setErrorMsg] = useState('');

  const password = useBoolean();

  const LoginSchema = Yup.object().shape({
    // email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    username: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      // await axios.post(`https://worker-dev.noody.net/api/admin/auth/login`, 
      await axios.post(`${HOST_API}/api/admin/auth/login`, 
      { "username":data.username, "password": data.password }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        // console.log(res);
        // console.log(res.data);
        // setStorage('Authenticated',true);
        var response = res.data;
        // localStorage.setItem('Token',response.data.token);
        // localStorage.setItem('User',JSON.stringify(response.data.user));
        setStorage('Token',response.data.token);
        setStorage('User',JSON.stringify(response.data.user));

        //change Authenticated state value in localStorage (for refresh action) because state return to default value if refresh page
        setAuthenticatedStatus(true);
        // change global Authenticated state current value
        setAuthenticated(true); 
      })
    } catch (error:any) {
      // console.error(error);
      // reset();
      // setErrorMsg(typeof error === 'string' ? error : error.message);
      setErrorMsg(typeof error === 'string' ? error : error.response?.data?.errors || 'An error occurred! please try again later.');
    }
  });

  return (
    <div className="App bg-gray-100 min-h-screen flex">
      {/* login form */}
      <div className="w-1/3 p-5 flex flex-col justify-center items-center">
        <img src={noodyIMG} alt="" />
        <span className="text-xl font-medium pb-12">Welcome to Noody</span>

        {/* flex flex-col gap-7 */}
        <div className="lg:w-4/5 md:w-full">
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2.5}>
              {!!errorMsg && <Alert severity="error" style={{ borderRadius: 20 }}>{errorMsg}</Alert>}
              <RHFRoundedTextField name="username" label="Username" type="text" helperText=""  autoComplete="new-password" />
              <RHFRoundedTextField name="password" label="Password"helperText="" autoComplete="new-password"
               type={password.value ? 'text' : 'password'}
               InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password.onToggle} edge="end">
                      <Iconify icon={password.value ? 'solar:eye-broken' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
               }} />
               
              <LoadingButton
                fullWidth
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                style={{ borderRadius: 20 }}
                className="bg-gradient-to-br from-red-400 to-orange-400 capitalize">
                  Login
                </LoadingButton>
                
                <Link to="/auth/delete-account" className="text-center mt-3 text-orange-500 hidden">Delete Account</Link>

                <span className='text-center hidden'>V0.0.3</span>
            </Stack>
          </FormProvider>
        </div>
      </div>

      {/* login gradient */}
      <div className="w-2/3 p-5 relative">
        <div className="bg-gradient-to-br from-red-400 to-orange-400 
          w-full h-full rounded-3xl flex items-center justify-center relative">
          {/* className="h-80" */}
          <img src={loginIMG} alt="" className="z-10 h-2/3" />
          <div className="absolute lg:w-2/3 md:w-4/5 h-auto">
            <img src={noIMG} alt="" className="top-0" />
            <img src={odyIMG} alt="" className="bottom-0" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;