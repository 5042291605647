export const BADGE_COLUMNS = [
    { id:'coverImageUrl', label: 'Cover', width: '6%', isImage: true },
    { id: 'title', label: 'Title', width: '30%'},
    { id: 'type', label: 'Type', width: '17%'},
    // { id: 'domain', label: 'Domain', width: '10%'},
    { id: 'totalCount', label: 'Count', width: '17%'},
    { id: 'createdAt', label: 'Created Date', width: '20%', isDate: true},
    { id: 'actions', width: '10%' },
];

export const BADGE_DUMMY_Data = [
    { id: '1', title: 'General', numberChallengeWinBadge: 0 },
    { id: '2', title: 'Travel', numberChallengeWinBadge: 2 },
    { id: '3', title: 'Swimming', numberChallengeWinBadge: 1 },
    { id: '4', title: 'Games', numberChallengeWinBadge: 1 },
    { id: '5', title: 'Space', numberChallengeWinBadge: 1 },
    { id: '6', title: 'Study', numberChallengeWinBadge: 1 },
];

export const TYPE_OPTIONS = [
    { id: 0, value: 'General' },
    { id: 1, value: 'Daily' },
    { id: 2, value: 'Weekly' },
];

export const DOMAIN_OPTIONS = [
    { id: 0, value: 'Follow Child' },
    { id: 1, value: 'Create Challenge' },
    { id: 2, value: 'Like Challenge' },
    { id: 3, value: 'Comment Challenge' },
    { id: 4, value: 'Do Challenge' },
    { id: 5, value: 'Create Story' },
    { id: 6, value: 'Like Story' },
    // { id: 7, value: 'Comment Story' },
    // { id: 8, value: 'Share Story' },
];

