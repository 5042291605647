import { Theme, alpha } from '@mui/material/styles';
import { tableRowClasses } from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';

// ----------------------------------------------------------------------

export function table(theme: Theme) {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          position: 'relative',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          [`&.${tableRowClasses.selected}`]: {
            backgroundColor: alpha(theme.palette.primary.dark, 0.04),
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.dark, 0.08),
            },
          },
          '&:last-of-type': {
            [`& .${tableCellClasses.root}`]: {
              borderColor: 'transparent', // last cell border bottom color
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: { // styling for table cells (borderBottom)
          // borderBottomStyle: 'dashed',
          borderBottom: '1px solid rgb(224, 224, 224)' // adding this style for increase contrast of table rows borderBottom
        },
        head: { // styling table head (table columns) background, textColor, fontSize, fontWeight, ...D
          fontSize: 14,
          color: theme.palette.text.secondary,
          fontWeight: theme.typography.fontWeightSemiBold,
          // backgroundColor: theme.palette.background.neutral,
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
        paddingCheckbox: { // set table checkbox padding from left
          paddingLeft: theme.spacing(1), // paddingLeft: theme.spacing(2),
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          width: '100%',
        },
        toolbar: { // Set pagination-bar height
          // height: 64, // larger like table toolbar // remove it for make it small height and for make toggle circle in middle of its rounded container
        },
        actions: { // add margin right to pagination actions
          // marginRight: 8,
          marginRight: 10,
        },
        select: { // add padding left between selection rows per page and lable rows per page
          paddingLeft: 8,
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          right: 4,
          width: 16,
          height: 16,
          top: 'calc(50% - 8px)',
        },
      },
    },
  };
}
