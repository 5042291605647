import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

interface NavigationListItemProps {
  item: { name: string; icon: React.ReactElement; };
  open: boolean;
  isActive: boolean;
  onClick: () => void;
}

const NavigationListItem: React.FC<NavigationListItemProps> = ({ item, open, isActive, onClick }) => {
  // const iconStyle: React.CSSProperties = {
  //   minWidth: 0,
  //   marginRight: open ? 1 : 'auto',
  //   justifyContent: 'center',
  //   // color: isActive ? '#ff0000' : 'inherit',
  // };

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      {/* Side Navbar Item with Styles */}
      <ListItemButton
        onClick={onClick} // Call the provided onClick callback
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          // px: 2.5,
          px: 2,
          backgroundColor: isActive ? '#FCEDDF' : 'transparent', // Example: Change background color when active
          borderLeft: isActive ? '0.4rem solid #FF922E':'0.4rem solid white',
          borderTopRightRadius: '2rem',
          borderBottomRightRadius: '2rem',
          width: '95%',
          // marginY: '.3rem'
        }}>

        {/* Side Navbar Item Icon */}
        {/* <ListItemIcon sx={iconStyle}> */}
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 1 : 'auto',
            justifyContent: 'center',
            // color: isActive ? '#ff0000' : 'inherit', // Change color for active item icon if icon is font
          }}>
          {item.icon}
        </ListItemIcon>
        
        {/* Side Navbar Item Text */}
        <ListItemText 
          primary={item.name} 
          sx={{ 
            opacity: open ? 1 : 0,
            // color: isActive ? '#FF922E' : 'inherit', // Change color for active item text
            color: isActive ? '#FF922E' : '#637381', // Change color for active item text
          }} />
      </ListItemButton>
    </ListItem>
  );
};

export default NavigationListItem;
