// AwardIcon.tsx

import React from 'react';

interface AwardIconProps {
  fillColor?: string;
  strokeColor?: string;
}

const AwardIcon: React.FC<AwardIconProps> = ({ fillColor = '#FF922E',strokeColor = '#FE7E6C' }) => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M3.65234 4.95633C3.65234 4.09188 4.35312 3.39111 5.21756 3.39111H18.7828C19.6472 3.39111 20.348 4.09189 20.348 4.95633V12.2607C20.348 16.8711 16.6106 20.6085 12.0002 20.6085C7.38977 20.6085 3.65234 16.8711 3.65234 12.2607V4.95633Z" fill="url(#paint0_linear_3230_4525)"/>
<path d="M20.3484 6.96268V4.95633C20.3484 4.09189 19.6476 3.39111 18.7832 3.39111H16.4721L4.33594 15.5736C5.05419 17.2331 6.29561 18.6133 7.85221 19.5064L20.3484 6.96268Z" fill="url(#paint1_linear_3230_4525)"/>
<defs>
<linearGradient id="paint0_linear_3230_4525" x1="-1.7405" y1="9.30659" x2="10.2167" y2="26.1804" gradientUnits="userSpaceOnUse">
<stop stopColor={strokeColor} />
<stop offset="1" stopColor={fillColor}/>
</linearGradient>
<linearGradient id="paint1_linear_3230_4525" x1="-0.836226" y1="8.92794" x2="10.2627" y2="24.9769" gradientUnits="userSpaceOnUse">
<stop stopColor={strokeColor} />
<stop offset="1" stopColor={fillColor}/>
</linearGradient>
</defs>
</svg>
  );
};

export default AwardIcon;
