import * as Yup from 'yup';

// ----------------------------------------------------------------------

export const ParentSchema = Yup.object().shape({
  name: Yup.string()
  .required('Full name is required')
  // .min(6, 'Mininum 6 characters')
  .max(32, 'Maximum 32 characters'),
  email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  // password: Yup.string()
  //   .required('Password is required')
  //   .min(6, 'Password should be of minimum 6 characters length'),
  // confirmPassword: Yup.string()
  //   .required('Confirm password is required')
  //   .oneOf([Yup.ref('password')], "Password's not match"),
  //
  // age: Yup.number()
  //   .required('Age is required')
  //   .moreThan(18, 'Age must be between 18 and 100')
  //   .lessThan(100, 'Age must be between 18 and 100'),
  //
  phoneNumber: Yup.string().required('Phone number is required'),
  address: Yup.string().required('Address is required'),
  country: Yup.string().required('Country is required'),
  company: Yup.string().required('Company is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  role: Yup.string().required('Role is required'),
  zipCode: Yup.string().required('Zip code is required'),
  avatarUrl: Yup.mixed<any>().nullable().required('Avatar is required'),
  // not required
  status: Yup.string(),
  isVerified: Yup.boolean(),
});
