// DashboardIcon.tsx

import React from 'react';

interface DashboardIconProps {
  fillColor?: string;
  strokeColor?: string;
}

const DashboardIcon: React.FC<DashboardIconProps> = ({ fillColor = '#FF922E',strokeColor = '#637381' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M14.0756 0H17.4616C18.8639 0 20.0001 1.14585 20.0001 2.55996V5.97452C20.0001 7.38864 18.8639 8.53449 17.4616 8.53449H14.0756C12.6734 8.53449 11.5371 7.38864 11.5371 5.97452V2.55996C11.5371 1.14585 12.6734 0 14.0756 0Z" fill="url(#paint0_linear_1856_4812)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.53852 0H5.92449C7.32676 0 8.46301 1.14585 8.46301 2.55996V5.97452C8.46301 7.38864 7.32676 8.53449 5.92449 8.53449H2.53852C1.13626 8.53449 0 7.38864 0 5.97452V2.55996C0 1.14585 1.13626 0 2.53852 0ZM2.53852 11.4655H5.92449C7.32676 11.4655 8.46301 12.6114 8.46301 14.0255V17.44C8.46301 18.8532 7.32676 20 5.92449 20H2.53852C1.13626 20 0 18.8532 0 17.44V14.0255C0 12.6114 1.13626 11.4655 2.53852 11.4655ZM17.4615 11.4655H14.0755C12.6732 11.4655 11.537 12.6114 11.537 14.0255V17.44C11.537 18.8532 12.6732 20 14.0755 20H17.4615C18.8637 20 20 18.8532 20 17.44V14.0255C20 12.6114 18.8637 11.4655 17.4615 11.4655Z" fill="url(#paint1_linear_1856_4812)"/>
    <defs>
    <linearGradient id="paint0_linear_1856_4812" x1="8.80348" y1="2.93224" x2="14.6855" y2="11.4205" gradientUnits="userSpaceOnUse">
    <stop stopColor={fillColor}/>
    <stop offset="1" stopColor={fillColor}/>
    </linearGradient>
    <linearGradient id="paint1_linear_1856_4812" x1="-6.46018" y1="6.87151" x2="7.28284" y2="26.8715" gradientUnits="userSpaceOnUse">
    <stop stopColor={fillColor}/>
    <stop offset="1" stopColor={fillColor}/>
    </linearGradient>
    </defs>
    </svg>
  );
};

export default DashboardIcon;
