import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog, DialogTitle, DialogContent, Box, Card, IconButton, Stack, Typography, Button, DialogActions, FormControlLabel, Checkbox } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { IQuestionItem } from '../../../../types/question';

import { useSnackbar } from '../../../../components/minimal/snackbar';

import { getQuestionSchema } from '../question-schema';

import FormProvider, { RHFRoundedTextField, RHFUpload } from '../../../../components/minimal/hook-form';
import Image from '../../../../components/minimal/image';
import Iconify from '../../../../components/minimal/iconify';

import { ASSETS_API, ASSETS_API_PREFIX, HOST_API } from '../../../../config-global';

import { fData } from '../../../../utils/format-number';
import { useAuth } from '../../../../hooks/useAuth';
import axios from 'axios';
import { getStorage } from '../../../../hooks/use-local-storage';
import { handleErrors } from '../../../../handlers/axiosErrorsHandler';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  currentQuestion?: IQuestionItem;
  questionId?: string;
};

export default function QuestionForm({ currentQuestion, questionId, open, onClose }: Props) {
  // use global Authenticated State to check its current value or to change its value
  const { setAuthenticated } = useAuth();
  // console.log('Authenticated: ', isAuthenticated);

  const { enqueueSnackbar } = useSnackbar();

  const [objData, setObjData] = useState<any>({});

  const token = getStorage('Token');

  // const isUpdate = !!currentQuestion; // Check if it's an update
  const isUpdate = currentQuestion?.id !== -1; // Check if it's an update

  const QuestionSchema = useMemo(() => getQuestionSchema(isUpdate), 
  [isUpdate]);
  // const NewUserSchema = Yup.object().shape({
  //   questionText: Yup.string().required('Question Text is required'),
  // });

  //must default values attributes is equal to stepSchema
  const defaultValues = useMemo(
    () => ({
      questionText: currentQuestion?.questionText || '',
      // questionImage: currentQuestion?.questionImageUrl || null,
      questionImage: null,
      questionStep: currentQuestion?.questionStep || 0,
      answers: currentQuestion?.answers || [], // Assuming answers are an array of objects
    }),
    [currentQuestion]
  );

  const methods = useForm({
    resolver: yupResolver(QuestionSchema),
    defaultValues,
  });

  const {
    // reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  var { fields, append, remove } = useFieldArray({ control: methods.control, name: 'answers' });

  const handleDropFile = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('questionImage', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveImage = useCallback(() => {
    setObjData((prevObjData: any) => {
      return { ...prevObjData, questionImageUrl: '' };
    });
  }, [setObjData]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if at least one answer is provided
      if (data?.answers?.length === 0) {
        enqueueSnackbar('Please provide at least one answer', { variant: 'error' });
        return; // Prevent form submission
      }

      // Check if any answerText is empty
      const hasEmptyAnswer = data?.answers?.some((answer) => answer?.answerText.trim() === '');

      if (hasEmptyAnswer) {
        enqueueSnackbar('Please fill in all answer texts', { variant: 'error' });
        return; // Prevent form submission
      }

      // Check if at least one correct answer is chosen
      const hasCorrectAnswer = data?.answers?.some((answer) => answer.correctAnswer);

      if (!hasCorrectAnswer) {
        enqueueSnackbar('Please choose the correct answer', { variant: 'error' });
        return; // Prevent form submission
      }

      const formData = new FormData();
      formData.append('questionText', data.questionText);
      if(!isUpdate)
        formData.append('questionStep', data.questionStep.toString());
      // else
      //   formData.append('answerQuestion', data.questionStep.toString());
      if(!isUpdate || data.questionImage){
        formData.append('questionImage', data.questionImage); // Assuming coverImage is a File object
      }
      // Append answers
      data.answers?.forEach((answer, index) => {
        formData.append(`answers[${index}][answerText]`, answer.answerText);
        formData.append(`answers[${index}][correctAnswer]`, answer.correctAnswer.toString());
        // formData.append(`answers[${index}][answerQuestion]`, '-1');
        formData.append(`answers[${index}][answerQuestion]`, answer.answerQuestion);
      });

      if (isUpdate) {
        // Update operation
        // response = await axios.put(`${HOST_API}/api/admin/award-steps/${id}`, formData, {
        // response = await axios.patch(`${HOST_API}/api/admin/award-steps/${id}`, formData, {
        await axios.patch(`${HOST_API}/api/admin/award-step-questions/${currentQuestion?.id}/answers`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-locale': 'en',
          }
        });
      } else {
        // Create operation
        // response = await axios.post(`${HOST_API}/api/admin/award-steps`, formData, {
        await axios.post(`${HOST_API}/api/admin/award-step-questions/answers`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-locale': 'en',
          }
        });
      }
      // await new Promise((resolve) => setTimeout(resolve, 500));
      // reset();
      onClose();
      // enqueueSnackbar('Update success!');
      // enqueueSnackbar(isUpdate ? 'Update success!' : 'Create success!');
      enqueueSnackbar(isUpdate ? 'Update success!' : 'Create success!', {
        // variant: 'success',
        // anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
      });
      // console.info('DATA', data);
    } catch (error) {
      // console.error(error);
      handleErrors(error, setAuthenticated, enqueueSnackbar);
    }
  });
  
  // State to track changes to correct answer
  // const [correctAnswerChanged, setCorrectAnswerChanged] = useState(false);

  useEffect(() => {
    const fillForm = () => {
        console.log(currentQuestion);
        setObjData(currentQuestion);
        // Populate form fields with fetched data
        setValue('questionText', currentQuestion?.questionText || '');
        // setValue('questionImage', currentQuestion?.questionImageUrl || '');
        setValue('questionImage', undefined);
        setValue('questionStep', currentQuestion?.questionStep || 0);
        // currentQuestion?.answers.forEach((answer: any) => {
        //   answer.answerQuestion = questionId;
        // });
        setValue('answers', currentQuestion?.answers || []);
        // setLoading(false); // Set loading to false when data fetching is complete
    };

    fillForm();

    // if (correctAnswerChanged) {
    //   // Reset state to trigger re-render
    //   setCorrectAnswerChanged(false);
    // }
  // }, [ currentQuestion, setValue, setObjData, correctAnswerChanged]);
  }, [ currentQuestion, setValue, setObjData]);

  const renderUploadFileField = (name: any, allowedExt: string, accept: any)=>(
    <RHFUpload
      name={name}
      maxSize={3145728}
      accept= {accept}// Specify accepted file types
      onDrop={handleDropFile}
      onDelete={() => setValue(name, null, { shouldValidate: true })}
      helperText={
        <Typography
          variant="caption"
          sx={{
            mt: 3,
            mx: 'auto',
            display: 'block',
            textAlign: 'center',
            color: 'text.disabled',
          }}
        >
          {/* Allowed *.jpeg, *.jpg, *.png, *.gif */}
          {allowedExt}
          <br /> max size of {fData(3145728)}
        </Typography>
      }
    />
  )

  const renderImageField = (imageUrl: string)=>(
    <div style={{ position: 'relative' }}>
      <Card sx={{ p: 1, mt: 0.5 }}>
        <Image ratio="1/1" alt="gallery" src={ASSETS_API + ASSETS_API_PREFIX + imageUrl} 
        sx={{borderRadius: 1, width: '100%', height:'300px', border: '1px', padding: 2}} 
        effect='opacity'
        />
      </Card>

      {/* Remove Image Icon */}
      {!isUpdate &&
        <Box sx={{ position: 'absolute', top: -12, right: -12 }}>
          <IconButton onClick={handleRemoveImage} 
          sx={{
            backgroundColor: '#FF922E',
            '&:hover': {backgroundColor: '#FF7F00',}
          }}>
            <Iconify icon={'solar:trash-bin-trash-bold'} color='white'/>
          </IconButton>
        </Box>
      }
      
    </div>
  )

  // const handleCorrectAnswerChange = (index: number, isChecked: boolean) => {
  const handleCorrectAnswerChange = (index: number) => {
    // Update correct answer for other options
    // setValue(`answers[${index}].correctAnswer` as any, isChecked);
    setValue(`answers[${index}].correctAnswer` as any, true);
    fields[index].correctAnswer = true;
    fields.forEach((option, idx) => {
      // if (idx !== index && option.correctAnswer === isChecked) {
      if (idx !== index && option.correctAnswer === true) {
        // setValue(`answers[${idx}].correctAnswer` as any, !isChecked);
        setValue(`answers[${idx}].correctAnswer` as any, false);
        fields[idx].correctAnswer = false;
      }
    });
    // renderAnswerFields();
    // setCorrectAnswerChanged(true); // Set state to trigger re-render
    remove(100);
  };

  const renderAnswerFields = () => {
    return fields.map((answer: any, index) => (
      <Stack key={answer.id} direction="row" spacing={2} alignItems="center" sx={{mt: 3}}>
        <RHFRoundedTextField
          name={`answers[${index}].answerText`}
          label={`Answer ${index + 1}`}
          disabled={isUpdate} // Disable the text field when isUpdate is true 
          />

        <FormControlLabel
        control={
          <Checkbox
            color="primary"
            disabled={isUpdate} // Disable the checkbox when isUpdate is true
            checked={answer.correctAnswer} // Set checked state to the current correct answer value
            // onChange={(e) => handleCorrectAnswerChange(index, e.target.checked)}
            onClick={(e) => {
              // Prevent click event when isUpdate is true
              if (isUpdate) {
                e.preventDefault();
                return;
              }
              handleCorrectAnswerChange(index);
            }}
          />
        }
        label="Correct"/>
        {/* <Button onClick={(e) => {
          if(!answer.correctAnswer){
            remove(index)
          }else{
            enqueueSnackbar(`Can't remove the correct answer`, { variant: 'error' });
          }
        }}>Remove</Button> */}

        {!isUpdate&&
          <IconButton onClick={(e) => {
            if(!answer.correctAnswer){
              remove(index)
            }else{
              enqueueSnackbar(`Can't remove the correct answer`, { variant: 'error' });
            }
            }}
            sx={{
              backgroundColor: '#FF922E',
              '&:hover': {backgroundColor: '#FF7F00',}
            }}>
            <Iconify icon={'solar:trash-bin-trash-bold'} color='white'/>
          </IconButton>
        }
      </Stack>
    ));
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        // sx: { maxWidth: 720 },
        sx: { maxWidth: 900 },
      }}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Question Dialog</DialogTitle>

        <DialogContent>
          {/* <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
            Account is waiting for confirmation
          </Alert> */}

          <Box>
            <Typography
              variant="caption"
              sx={{
                display: 'block',
                fontSize: '11pt',
              }}>
              Question Image
            </Typography>
            {isUpdate && objData?.questionImageUrl ? ( // Check if it's an update and questionImage exists
              renderImageField(objData?.questionImageUrl)
            ) : (
              renderUploadFileField("questionImage", "Allowed *.jpeg, *.jpg, *.png",
              { 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg']},)
            )}
          </Box>

          <Stack sx={{mt: 3}}>
            {/* // Disable the text field when isUpdate is true */}
            <RHFRoundedTextField name="questionText" label="Question Text" disabled={isUpdate} />
            {renderAnswerFields()}
            {/* Button to add new answer field */}
            {!isUpdate &&
              <Button onClick={() => append({answerQuestion: questionId, answerText: '', correctAnswer: false })} sx={{ mt: 3 }}>Add Answer</Button>
            }
          </Stack>

          {/* <Stack sx={{mt: 3}}>
            <RHFRoundedTextField name="correctAnswer" label="Correct Answer" />
          </Stack> */}
          {/* <Stack sx={{mt: 3}}>
            <RHFRoundedTextField name="Answer" label="Answer" />
          </Stack>
          <Stack sx={{mt: 3}}>
            <RHFRoundedTextField name="Answer" label="Answer" />
          </Stack>
          <Stack sx={{mt: 3}}>
            <RHFRoundedTextField name="Answer" label="Answer" />
          </Stack> */}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          {!isUpdate &&
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {isUpdate ? 'Update Question' : 'Create Question'}
            </LoadingButton>
          }
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
