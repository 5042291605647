import { Avatar } from '@mui/material';
import { FC } from 'react';
import { ASSETS_API, ASSETS_API_PREFIX } from '../../../config-global';
import { Message } from '../../../types/chat';
import Label from '../../minimal/label';

interface ChatBubbleProps {
    message: Message;
    currentUser: any;
    // formatTime: (timestamp: string) => string;
}

const ChatBubble: FC<ChatBubbleProps> = ({ message, currentUser })=>{
    const formatTime = (timestamp: string) => {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero if needed
        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };
    return(
        <div className={`message ${message.senderId === currentUser.id ? 'flex-row-reverse text-right' : 'flex-row text-left'} mb-5 flex`}>
            <Avatar 
                alt='' 
                src={message?.sender?.imageURL === '' || message?.sender?.imageURL === null ? 
                '' : ASSETS_API + ASSETS_API_PREFIX + message?.sender?.imageURL} 
                sx={{ marginLeft: message.senderId === currentUser.id ? 2 : 0, marginRight: message.senderId === currentUser.id ? 0 : 2 }} 
            />
            <div className='flex flex-col'>
                <span className="text-xs" style={{ color: "#919EAB" }}>{formatTime(message.createdAt!!)}</span>
                {message.type === 'image' ? (
                    <img src={ASSETS_API + ASSETS_API_PREFIX + message.text} alt="Chat Image" className="chat-image w-80" />
                ) : (
                <Label className="w-fit" style={{ padding: ".8rem" }}>
                    <p className="text-sm">{message.text}</p>
                </Label>
                )}
            </div>
        </div>
    );
};

export default ChatBubble;