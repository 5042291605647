import Box from '@mui/material/Box';
// import Chip from '@mui/material/Chip';
// import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';

import Iconify from '../../minimal/iconify';

// import { ITableFilterValue, ITableFilters } from '../types/filter';

// ----------------------------------------------------------------------

type Props = StackProps & {
  // filters: ITableFilters;
  // onFilters: (name: string, value: IUserTableFilterValue) => void;
  // onFilters: (name: string, value: any) => void;
  filters: any;
  onFilters: (name: string, value: any) => void;
  //
  onResetFilters: VoidFunction;
  //
  results: number;
};

export default function DataTableFiltersResult({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}: Props) {
  // const handleRemoveStatus = () => {
  //   onFilters('status', 'all');
  // };

  // const handleRemoveRole = (inputValue: string) => {
  //   const newValue = filters.role.filter((item:any) => item !== inputValue);
  //   onFilters('role', newValue);
  // };

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          results found
          <Button
            color="error"
            onClick={onResetFilters}
            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
            sx={{ml: 2}}>
            Clear
          </Button>
        </Box>
      </Box>

      {/* <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap" alignItems="center"> */}
        {/* {filters.status !== 'all' && (
          <Block label="Status:">
            <Chip size="small" label={filters.status} onDelete={handleRemoveStatus} />
          </Block>
        )} */}

        {/* {!!filters.role.length && (
          <Block label="Role:">
            {filters.role.map((item:any) => (
              <Chip key={item} label={item} size="small" onDelete={() => handleRemoveRole(item)} />
            ))}
          </Block>
        )} */}

        {/* <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          Clear
        </Button> */}
      {/* </Stack> */}
    </Stack>
  );
}

// ----------------------------------------------------------------------

// type BlockProps = StackProps & {
//   label: string;
// };

// function Block({ label, children, sx, ...other }: BlockProps) {
//   return (
//     <Stack
//       component={Paper}
//       variant="outlined"
//       spacing={1}
//       direction="row"
//       sx={{
//         p: 1,
//         borderRadius: 1,
//         overflow: 'hidden',
//         borderStyle: 'dashed',
//         ...sx,
//       }}
//       {...other}
//     >
//       <Box component="span" sx={{ typography: 'subtitle2' }}>
//         {label}
//       </Box>

//       {/* <Stack spacing={1} direction="row" flexWrap="wrap">
//         {children}
//       </Stack> */}
//     </Stack>
//   );
// }
