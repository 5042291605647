// filterUtils.ts

export function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: any[];
  comparator: (a: any, b: any) => number;
  filters: any;
}) {
  // const { name, status, role } = filters;
  // const {name} = filters;
  const searchText =  Object.values(filters)[0];

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  const firstFieldKey = Object.keys(filters)[0];
  // const searchKey = Object.keys(filters)[0];
  
  // if (name) {
  if (searchText) {
    inputData = inputData.filter((item) => {
      // return item[firstFieldKey].toLowerCase().indexOf(name.toLowerCase()) !== -1
        return item[firstFieldKey]?.toLowerCase().indexOf(searchText?.toString().toLowerCase()) !== -1
    });
  }
  // const firstFilterKey = Object.keys(filters)[0];

  // if (filters[firstFilterKey]) {
  //   inputData = inputData.filter((item) => {
  //       return item["title"].toLowerCase().indexOf(filters[firstFilterKey].toLowerCase()) !== -1
  //   });
  // }
  // if (name) {
  //   inputData = inputData.filter((item) => {
  //       return item.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
  //   });
  // }

  // if (status !== 'all') {
  //   inputData = inputData.filter((item) => item.status === status);
  // }

  // if (role.length) {
  //   inputData = inputData.filter((item) => role.includes(item.role));
  // }

  return inputData;
}
