export const CHALLENGE_USER_REPORT_COLUMNS = [
    { id: 'challengeUserId', label: 'Challenge Id', width: '10%'},
    // { id: 'reportUserId', label: 'Report UserId', width: '30%'},
    { id: 'challengeUser', label: 'Challenge Username', width: '15%', isObjObj: true ,child: 'user', child2: 'username'},
    { id: 'user', label: 'Reporting Username', width: '15%', isObj: true, child:'username'},
    { id: 'createdAt', label: 'Reporting Date', width: '15%', isDate: true},
    { id: 'reason', label: 'Reason', width: '35%'},
    { id: 'actions', width: '10%' },
];

export const CHALLENGE_USER_REPORT_DUMMY_Data = [
    { id: '1', title: 'General', numberChallengeWinBadge: 0, standard: true, published: true },
    { id: '2', title: 'Travel', numberChallengeWinBadge: 2, standard: false, published: true },
    { id: '3', title: 'Swimming', numberChallengeWinBadge: 1, standard: false, published: true },
    { id: '4', title: 'Games', numberChallengeWinBadge: 1, standard: false, published: true },
    { id: '5', title: 'Space', numberChallengeWinBadge: 1, standard: false, published: true },
    { id: '6', title: 'Study', numberChallengeWinBadge: 1, standard: false, published: true },
];

export const CHALLENGE_USER_REPORT_OPTIONS = [
    { id: '1', value: 'General' },
    { id: '2', value: 'Travel' },
    { id: '3', value: 'Swimming' },
    { id: '4', value: 'Games' },
];

