import { Controller, useFormContext } from 'react-hook-form';

import TextField, { TextFieldProps } from '@mui/material/TextField';

// ----------------------------------------------------------------------

const styles = {
  '& .MuiOutlinedInput-root': {
    // borderRadius: '9999px',
    '& fieldset': {
        borderColor: 'black',
    },
    '&:hover fieldset': {
        borderColor: '#f87171',
    },
    '&.Mui-focused fieldset': {
        borderColor: '#f87171',
    },
  },
  '& label': {
      // color: 'red',
  },
  '& label.Mui-focused': {
      color: '#f87171',
  },
}

type Props = TextFieldProps & {
  name: string;
  autoComplete?: string; // Add autocomplete property to Props type
};

export default function RHFMultiLineTextField({ 
  name, 
  helperText = "", 
  type = 'text', 
  size = 'small', 
  fullWidth = true, 
  autoComplete, // Add autocomplete to props
  multiline = false,
  ...other 
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth = {fullWidth}
          type={type}
          size={size}
          multiline = {multiline}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          sx={styles}
          inputProps={{ autoComplete }} // Pass autocomplete directly to inputProps
          {...other}
        />
      )}
    />
  );
}
