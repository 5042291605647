// AuthContext.tsx
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from "react";
import { getAuthenticatedStatus } from "../interceptors/auth";

type AuthContextType = {
  isAuthenticated?: boolean;
  setAuthenticated: Dispatch<SetStateAction<boolean>>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // get Authenticated State from localStorage('Authenticated')
  const [isAuthenticated, setAuthenticated] = useState<boolean>(getAuthenticatedStatus());

  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
