import React, { FC, MouseEvent } from 'react';
import { Button } from '@mui/material';
import Iconify from '../../minimal/iconify';

interface RoundedButtonProps {
  type?: 'button' | 'submit';
  label: string;
  fullWidth?: boolean;
  // className?: string;
  backgroundColor?: string;
  color?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  icon?: string;
}

const RoundedButton: FC<RoundedButtonProps> = ({ 
  type = 'button', 
  label, 
  fullWidth = false,
  // className="bg-gradient-to-br from-red-400 to-orange-400 capitalize",
  // className = '',
  backgroundColor,
  color,
  icon = '',
  onClick 
}) => {
  return (
    <Button
      type={type}
      // fullWidth
      fullWidth = {fullWidth}
      variant="contained"
      color="primary"
      onClick={onClick}
      // className={className}
      // className={`bg-gradient-to-br from-red-400 to-orange-400 capitalize ${className}`}
      startIcon={icon ? <Iconify icon={icon} /> : null}
      style={{ 
        borderRadius: 20,
        backgroundColor,
        color,
        textTransform: "capitalize",
      }}>
      {label}
    </Button>
  );
};

export default RoundedButton;