import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

const styles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '9999px',
      '& fieldset': {
          borderColor: 'black',
      },
      '&:hover fieldset': {
          borderColor: '#f87171',
      },
      '&.Mui-focused fieldset': {
          borderColor: '#f87171',
      },
    },
    '& label': {
        // color: 'red',
    },
    '& label.Mui-focused': {
        color: '#f87171',
    },
  }

//Text Field Properties + This Properties
type RoundedTextFieldProps = TextFieldProps & {
    type?: string;
    label: string;
    variant?: 'filled' | 'outlined' | 'standard';
    fullWidth?: boolean;
    color?: any;
    value: string; 
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; 
}

const RoundedTextFieldComponent: React.FC<RoundedTextFieldProps> = ({
    type = 'text',
    label,
    variant = 'outlined',
    fullWidth = false,
    color,
    value,
    onChange,
    ...other
}) => {
    return (
        <TextField
            type={type}
            label={label}
            variant={variant}
            fullWidth={fullWidth}
            color={color}
            margin='none' // remove default margins
            size="small" // size of TextField Height
            value={value} // Bind value prop
            onChange={onChange} // Bind onChange prop
            sx={styles}
            {...other}
        />
    );
};

export default RoundedTextFieldComponent;