// CustomizableTable.tsx

import React from 'react';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  Column,
  HeaderGroup,
  // Row,
  Cell,
} from 'react-table';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Checkbox } from '@mui/material';

interface MyTableProps {
  columns: Column[];
  data: any[];
  tableName: string;
  showCheckboxColumn?: boolean;
}

const CTable: React.FC<MyTableProps> = ({ columns, data, tableName, showCheckboxColumn = false }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    { columns, data },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
  ) as any; // Type casting to any to avoid TypeScript errors

  const { globalFilter } = state;

  return (
    <div>
    <div className="flex flex-row items-center justify-between">
      <h2>{tableName}</h2>
      <TextField
        label="Search"
        variant="outlined"
        size="small"
        value={globalFilter || ''}
        onChange={(e) => {
          if (state.setGlobalFilter) {
            state.setGlobalFilter(e.target.value);
          }
        }}
      />
      </div>

      <TableContainer component={Paper} className="mt-3">
        <Table {...getTableProps()} style={{ backgroundColor: 'white' }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={columns.length} align="left" style={{ fontWeight: 'bold' }}>
                {tableName}
              </TableCell>
            </TableRow>
            {headerGroups.map((headerGroup: HeaderGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} style={{ background: '#DFE3E8', color: '#637381' }}>
                {showCheckboxColumn && (
                  <TableCell style={{ minWidth: 'fit-content' }}>
                    <Checkbox
                      indeterminate={
                        selectedFlatRows.length > 0 && selectedFlatRows.length < rows.length
                      }
                      checked={selectedFlatRows.length === rows.length}
                      onChange={() => {
                        // Toggle all rows selected
                        selectedFlatRows.forEach((row: any) =>
                          row.toggleRowSelected(!selectedRowIds[row.id])
                        );
                      }}
                    />
                  </TableCell>
                )}
                {headerGroup.headers.map((column: any) => (
                  <TableCell {...column.getHeaderProps()} style={{ color: '#637381' }}>{column.render('Header')}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row: any, index: number) => {
              prepareRow(row);
              return (
                <TableRow 
                  {...row.getRowProps()}
                  // style={{
                  //   background: index % 2 !== 0 ? 'white' : '#f2f2f2', // Alternating row colors
                  // }}
                >
                  {showCheckboxColumn && (
                    <TableCell style={{ minWidth: 'fit-content' }}>
                      <Checkbox {...row.getToggleRowSelectedProps()} />
                    </TableCell>
                  )}
                  {row.cells.map((cell: Cell) => (
                    <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ background: '#DFE3E8', color: '#637381', height:'3rem', display:'flex', alignItems:'center', justifyContent:'end' }}>
        <span className="mx-3">
          Page{' '}
          <strong>
            {state.pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <div className="mx-3 gap-3 flex">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="cursor-pointer">
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="cursor-pointer">
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage} className="cursor-pointer">
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="cursor-pointer">
          {'>>'}
        </button>{' '}
        </div>
      </div>
    </div>
  );
};

export default CTable;
