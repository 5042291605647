// ChallengeSVG.tsx

import React from 'react';

interface ChallengeSVGProps {
  fillColor?: string;
  strokeColor?: string;
}

const ChallengeSVG: React.FC<ChallengeSVGProps> = ({ fillColor = '#FF922E',strokeColor = '#637381' }) => {
  return (
    // <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
        fill={fillColor}/>
      <path d="M3.96668 13.7219L3 21L7.5884 18.247C7.73805 18.1572 7.81288 18.1123 7.89276 18.0947C7.96341 18.0792 8.03659 18.0792 8.10724 18.0947C8.18712 18.1123 8.26195 18.1572 8.4116 18.247L13 21L12.0343 13.7212M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
        stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default ChallengeSVG;
