// BadgeSVG.tsx

import React from 'react';

interface BadgeSVGProps {
  fillColor?: string;
  strokeColor?: string;
}

const BadgeSVG: React.FC<BadgeSVGProps> = ({ fillColor = '#FF922E',strokeColor = '#637381' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.32" d="M11.9995 16.0002C6.23954 16.0002 5.21954 10.2602 5.03954 5.70623C4.98954 4.44023 4.96354 3.80623 5.43954 3.22123C5.91554 2.63523 6.48454 2.53923 7.62354 2.34723C9.07003 2.11016 10.5338 1.99409 11.9995 2.00023C13.7825 2.00023 15.2525 2.15723 16.3765 2.34723C17.5145 2.53923 18.0845 2.63523 18.5595 3.22123C19.0355 3.80723 19.0105 4.44023 18.9595 5.70623C18.7795 10.2592 17.7595 16.0002 11.9995 16.0002Z" 
        fill={fillColor}/>
      <path d="M17.64 12.422L20.457 10.857C21.209 10.439 21.585 10.23 21.793 9.878C22 9.526 22 9.096 22 8.235V8.162C22 7.119 22 6.597 21.717 6.204C21.434 5.811 20.939 5.646 19.949 5.316L19 5L18.983 5.085C18.978 5.274 18.97 5.48 18.961 5.706C18.873 7.931 18.584 10.439 17.641 12.422M5.04 5.706C5.127 7.931 5.416 10.439 6.36 12.422L3.543 10.857C2.791 10.439 2.414 10.23 2.207 9.878C2 9.526 2 9.096 2 8.235V8.162C2 7.119 2 6.597 2.283 6.204C2.566 5.811 3.061 5.646 4.051 5.316L5 5L5.017 5.087C5.022 5.275 5.03 5.481 5.039 5.707" 
        fill={fillColor}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.25 22C5.25 21.8011 5.32902 21.6103 5.46967 21.4697C5.61032 21.329 5.80109 21.25 6 21.25H18C18.1989 21.25 18.3897 21.329 18.5303 21.4697C18.671 21.6103 18.75 21.8011 18.75 22C18.75 22.1989 18.671 22.3897 18.5303 22.5303C18.3897 22.671 18.1989 22.75 18 22.75H6C5.80109 22.75 5.61032 22.671 5.46967 22.5303C5.32902 22.3897 5.25 22.1989 5.25 22Z" 
        fill={fillColor}/>
      <path opacity="0.32" d="M15.458 21.2503H8.54297L8.83997 19.5003C8.88526 19.2737 9.00764 19.0698 9.1863 18.9232C9.36496 18.7766 9.58888 18.6964 9.81997 18.6963H14.18C14.4112 18.6962 14.6354 18.7763 14.8142 18.9229C14.9931 19.0695 15.1156 19.2735 15.161 19.5003L15.458 21.2503Z" 
        fill={fillColor}/>
      <path d="M12.0003 16C11.7403 16 11.4903 15.989 11.2503 15.966V18.696H12.7503V15.966C12.501 15.9891 12.2507 16.0004 12.0003 16ZM11.1463 6.023C11.5263 5.34 11.7163 5 12.0003 5C12.2843 5 12.4743 5.34 12.8543 6.023L12.9523 6.199C13.0603 6.393 13.1143 6.489 13.1983 6.553C13.2833 6.617 13.3883 6.641 13.5983 6.688L13.7883 6.732C14.5263 6.899 14.8953 6.982 14.9833 7.264C15.0713 7.547 14.8193 7.841 14.3163 8.429L14.1863 8.581C14.0433 8.748 13.9713 8.831 13.9393 8.935C13.9073 9.039 13.9183 9.15 13.9393 9.373L13.9593 9.576C14.0353 10.361 14.0733 10.754 13.8443 10.928C13.6143 11.102 13.2683 10.943 12.5773 10.625L12.3993 10.543C12.2023 10.453 12.1043 10.408 12.0003 10.408C11.8963 10.408 11.7983 10.453 11.6013 10.543L11.4233 10.625C10.7323 10.944 10.3863 11.102 10.1563 10.928C9.92628 10.754 9.96528 10.361 10.0413 9.576L10.0613 9.373C10.0823 9.15 10.0933 9.039 10.0613 8.935C10.0293 8.832 9.95728 8.748 9.81428 8.581L9.68428 8.429C9.18128 7.841 8.92928 7.547 9.01728 7.264C9.10528 6.982 9.47428 6.899 10.2123 6.732L10.4023 6.688C10.6123 6.641 10.7173 6.618 10.8023 6.553C10.8863 6.489 10.9403 6.393 11.0483 6.199L11.1463 6.023Z" 
        fill={fillColor}/>
    </svg>
  );
};

export default BadgeSVG;
