export const QUESTION_COLUMNS = [
    { id:'questionImageUrl', label: 'Image', width: '7%', isImage: true },
    { id: 'questionText', label: 'Question Text', width: '58%'},
    // { id: 'stepAward', label: 'Award', width: '24%', isObj: true, child:'title'},
    { id: 'createdAt', label: 'Created Date', width: '20%', isDate: true},
    { id: 'actions', width: '15%' },
];

export const QUESTION_DUMMY_Data = [
    { id: '1', title: 'General', numberChallengeWinBadge: 0, standard: true, published: true },
    { id: '2', title: 'Travel', numberChallengeWinBadge: 2, standard: false, published: true },
    { id: '3', title: 'Swimming', numberChallengeWinBadge: 1, standard: false, published: true },
    { id: '4', title: 'Games', numberChallengeWinBadge: 1, standard: false, published: true },
    { id: '5', title: 'Space', numberChallengeWinBadge: 1, standard: false, published: true },
    { id: '6', title: 'Study', numberChallengeWinBadge: 1, standard: false, published: true },
];

export const QUESTION_OPTIONS = [
    { id: '1', value: 'General' },
    { id: '2', value: 'Travel' },
    { id: '3', value: 'Swimming' },
    { id: '4', value: 'Games' },
];

