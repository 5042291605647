import React, { useMemo, useCallback } from 'react';

// import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// import { Controller, useForm } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import axios from 'axios';

// import { Alert, Box, Card, Grid, Stack, Button, Switch, IconButton, InputAdornment, 
  // Typography, FormControlLabel } from '@mui/material';
import { Box, Card, Grid, Stack, Button, IconButton, InputAdornment, Typography } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import FormProvider, {
    RHFRoundedTextField, 
    // RHFSelect, 
    RHFSwitch,
    // RHFTextField,
    RHFUploadAvatar,
    // RHFAutocomplete,
  } from '../../../../components/minimal/hook-form';
// import Label from '../../../../components/minimal/label';
import Iconify from '../../../../components/minimal/iconify';
import { useSnackbar } from '../../../../components/minimal/snackbar';

// import RoundedButton from '../../../../components/common/button/rounded-button';

import { IAdminItem } from '../../../../types/admin';
import { fData } from '../../../../utils/format-number';

import { useBoolean } from '../../../../hooks/use-boolean';

import { AdminSchema } from '../admin-schema';
import { HOST_API } from '../../../../config-global';
import { handleErrors } from '../../../../handlers/axiosErrorsHandler';

import { useAuth } from '../../../../hooks/useAuth';

// ----------------------------------------------------------------------

interface AdminFormProps {
  onClose: VoidFunction;
  currentUser?: IAdminItem;
}

// ----------------------------------------------------------------------

const AdminForm: React.FC<AdminFormProps> = ({currentUser, onClose}) => {
  // const [errorMsg, setErrorMsg] = useState('');
  // use global Authenticated State to check its current value or to change its value
  const { setAuthenticated } = useAuth();
  // console.log('Authenticated: ', isAuthenticated);

  const password = useBoolean();
  const { enqueueSnackbar } = useSnackbar();

  // const isUpdate = !!currentUser; // Check if it's an update

  //must default values attributes is equal to categorySchema
  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || '',
      city: currentUser?.city || '',
      role: currentUser?.role || '',
      email: currentUser?.email || '',
      password: currentUser?.password || '',
      state: currentUser?.state || '',
      status: currentUser?.status || '',
      address: currentUser?.address || '',
      country: currentUser?.country || '',
      zipCode: currentUser?.zipCode || '',
      company: currentUser?.company || '',
      avatarUrl: currentUser?.avatarUrl || null,
      phoneNumber: currentUser?.phoneNumber || '',
      isVerified: currentUser?.isVerified || true,
    }),
    [currentUser]
  );

    const methods = useForm({
        resolver: yupResolver(AdminSchema),
        defaultValues,
    });

    const {
      // reset,
      // watch,
      // control,
      setValue,
      handleSubmit,
      formState: { isSubmitting },
    } = methods;

    // const values = watch();

    const onSubmit = handleSubmit(async (data) => {
        try {
          // await axios.post(`https://worker-dev.noody.net/api/admin/auth/login`, 
          await axios.post(`${HOST_API}/api/admin/auth/login`, 
          // { "username":data.username, "password": data.password }, {
          { "name":data.name, "phoneNumber": data.phoneNumber, "email": data.email }, {
            headers: {
                'Content-Type': 'application/json',
            }
          })
          .then(res => {
            // console.log(res);
            // console.log(res.data);
            enqueueSnackbar(currentUser ? 'Update success!' : 'Create success!');
          })
        } catch (error:any) {
          // reset();
          handleErrors(error, setAuthenticated, enqueueSnackbar);
        }
    });

    const handleDrop = useCallback(
      (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
  
        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
  
        if (file) {
          setValue('avatarUrl', newFile, { shouldValidate: true });
        }
      },
      [setValue]
    );

    return(
      <FormProvider methods={methods} onSubmit={onSubmit}>
      {/* <div class"container"></div> */}
      <Grid container spacing={3}> 
        {/* <div class="col-md-4 col-xs-12"></div> */}
        <Grid item xs={12} md={4}>
          {/* , maxHeight: '26rem' */}
          <Card sx={{ pt: 5, pb: 5, px: 3, minHeight: '26rem' }}>
            {/* {currentUser && (
              <Label
                color={
                  (values.status === 'active' && 'success') ||
                  (values.status === 'banned' && 'error') ||
                  'warning'
                }
                sx={{ position: 'absolute', top: 24, right: 24 }}
              >
                {values.status}
              </Label>
            )} */}

            {/* Rounded Upload File for Avatar/User Profile*/}
            {/* helperText is a text displayed under upload file to tell the user what to upload */}
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>

            {/* {currentUser && (
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value !== 'active'}
                        onChange={(event) =>
                          field.onChange(event.target.checked ? 'banned' : 'active')
                        }
                      />
                    )}
                  />
                }
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Banned
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Apply disable account
                    </Typography>
                  </>
                }
                sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
              />
            )} */}

            <RHFSwitch
              name="isVerified"
              labelPlacement="start"
              label={
                <>
                  <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Email Verified
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Disabling this will automatically send the user a verification email
                  </Typography>
                </>
              }
              sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
            />

            {currentUser && (
              <Stack justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                <Button variant="soft" color="error">
                  Delete User
                </Button>
              </Stack>
            )}
          </Card>
        </Grid>

        {/* <div class="col-md-8 col-xs-12"></div> */}
        <Grid item xs={12} md={8}>
          {/* , maxHeight: '26rem' */}
          <Card sx={{ p: 3, minHeight: '26rem' }}>
            {/* rowGap is a gap between form rows 
                columnGap is a gap between form columns*/}
            <Box
              rowGap={3} // gap between form rows
              columnGap={2} // gap between form columns
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFRoundedTextField name="name" label="Full Name" autoComplete="new-password" />
              <RHFRoundedTextField name="phoneNumber" label="Phone Number" />
              <RHFRoundedTextField name="email" label="Email Address" autoComplete="new-password" />
              <RHFRoundedTextField name="password" label="Password"
               type={password.value ? 'text' : 'password'}
               InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password.onToggle} edge="end">
                      <Iconify icon={password.value ? 'solar:eye-broken' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
               }} />
              <RHFRoundedTextField name="state" label="State/Region" />
              <RHFRoundedTextField name="city" label="City" />
              <RHFRoundedTextField name="address" label="Address" autoComplete="new-password" />
              <RHFRoundedTextField name="zipCode" label="Zip/Code" />
              <RHFRoundedTextField name="company" label="Company" autoComplete="new-password" />
              <RHFRoundedTextField name="role" label="Role" />
              
            </Box>

            {/* Form Actions Bar */}
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              {/* <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!currentUser ? 'Create User' : 'Save Changes'}
              </LoadingButton> */}
              <LoadingButton type="submit" variant="contained" color="primary" size="large" loading={isSubmitting}
                style={{ borderRadius: 20,textTransform: 'capitalize' }}
                className="bg-gradient-to-br from-red-400 to-orange-400 capitalize">
                  {!currentUser ? 'Create Admin' : 'Save Admin'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
    )
};

export default AdminForm;