import React, { useCallback, useState ,useEffect } from 'react';

import isEqual from 'lodash/isEqual';

// import RoundedButton from '../../../components/common/button/rounded-button';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';
import { 
  useTable,  
  emptyRows,
  getComparator,
} from '../../../components/minimal/table';

// import { IStoryReportItem, IStoryReportTableFilters, IStoryReportTableFilterValue } from '../../../types/story-report';
// import {STORY_REPORT_COLUMNS, STORY_REPORT_DUMMY_DATA} from '../../../consts/story-report';
import { IStoryReportTableFilters, IStoryReportTableFilterValue } from '../../../types/story-report';
import { STORY_REPORT_COLUMNS } from '../../../consts/story-report';

import DataTable from '../../../components/common/data-table/data-table';

import { applyFilter } from '../../../utils/data-table-filter';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { HOST_API } from '../../../config-global';
// import handleAxiosErrors from '../../../interceptors/axios-errors';
import { handleErrors } from '../../../handlers/axiosErrorsHandler';
import { getStorage } from '../../../hooks/use-local-storage';
import { routes } from '../../../routes/routes';

// import { setAuthenticatedStatus } from '../../../interceptors/auth';
import { useAuth } from '../../../hooks/useAuth';

import { useSnackbar } from '../../../components/minimal/snackbar';

// ----------------------------------------------------------------------

interface StoryReportProps {
}

// ----------------------------------------------------------------------

// const dummyData: IStoryReportItem[] = STORY_REPORT_DUMMY_Data;

const defaultFilters: IStoryReportTableFilters = {
  title: '',
};

// ----------------------------------------------------------------------

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StoryReport: React.FC<StoryReportProps> = () => {
  // use global Authenticated State to check its current value or to change its value
  const { setAuthenticated } = useAuth();
  // console.log('Authenticated: ', isAuthenticated);

  // const token = localStorage.getItem('Token');
  const token = getStorage('Token');

  const { enqueueSnackbar } = useSnackbar();

  const table = useTable();

  const navigate = useNavigate(); // Hook for navigation
  // const router = useRouter(); 

  const [tableData, setTableData] = useState([]);
  // const [tableData, setTableData] = useState(dummyData);
  
  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get(`${HOST_API}/api/admin/user-stories`, {
          headers: {
            // 'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'x-locale': 'en',
          }
        }).then(res =>{
          var response = res.data;
          setTableData(response.data.items);
          // setLoading(false);
        })
      } catch (error: any) {
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      }
      // setLoading(false);
    };

    fetchData();
  }, [token, setAuthenticated, enqueueSnackbar]); // Empty dependency array to run only once on mount

  const denseHeight = table.dense ? 52 : 72;

  const resetFilters = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && resetFilters) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IStoryReportTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);
  
  // const handleAddRow = () =>{
  //   navigate(routes.categoriesCreate);
  // }

  const handleEditRow = useCallback(
    (id: string) => {
      // navigate(routes.categoriesEdit(id));
      // navigate(routes.categoriesEdit);
    },
    [navigate]
    // (id: string) => {
    //   router.push(routes.categoriesEdit(id));
    // },
    // [router]
  );

  const handleDeleteRow = useCallback(
    async (id: string) => {
      // Send delete request using Axios
      await axios.delete(`${HOST_API}/api/admin/challenge-categories/${id}`, {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'x-locale': 'en',
        }
      })
      .then(res => {
        // Check if the request was successful
        if (res.status === 200) {
          // var response = res.data;
          // If successful, update the table data state
          const deleteRow = tableData.filter((row:any) => row.id !== id);
          setTableData(deleteRow);

          // Update any other relevant state or perform actions
          table.onUpdatePageDeleteRow(dataInPage.length);

          enqueueSnackbar('Delete success!', {
            // variant: 'success',
            // anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
          });
        } else {
          // Handle any errors or display error messages
          console.error('Delete request failed');
        }
      })
      .catch(error => {
        // Handle any errors or display error messages
        // console.error('Delete request failed:', error);
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      });
    },
    [dataInPage.length, table, tableData, token, setAuthenticated, enqueueSnackbar]
  );

  // const handleDeleteRows = useCallback(() => {
  //   const deleteRows = tableData.filter((row:any) => !table.selected.includes(row.id));
  //   setTableData(deleteRows);

  //   table.onUpdatePageDeleteRows({
  //     totalRows: tableData.length,
  //     totalRowsInPage: dataInPage.length,
  //     totalRowsFiltered: dataFiltered.length,
  //   });
  // }, [dataFiltered.length, dataInPage.length, table, tableData]);
  
  const handleChangeState = useCallback(
    (id: string) => {
    return null;
  },[]);

  // State to hold selected rows
  const [selectedRows, setSelectedRows] = useState<any>([]);

  // Function to handle row selection
  const handleSelectRow = useCallback((id: string, selectionMode: string)=>{
    // Check if the row is already selected
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected:any = [];

    table.onSelectRow(id);
    if (selectedIndex === -1) {
      // If not selected, add to the selection
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      // If first element is selected, remove it
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      // If last element is selected, remove it
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      // If middle element is selected, remove it
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
      
    }

    // Update the selected rows state
    setSelectedRows(newSelected);
  },
  [table, selectedRows]
  );

  return (
    <div>
      {/* Bread Crumb + Create Button */}
      <div className='flex items-center justify-between pb-3 h-12'> 
        {/* <h2>Overview / Story Report</h2> */}
        <CustomBreadcrumbs
          // heading="List"
          links={[
            { name: 'Overview', href: routes.storyReports },
            { name: 'Story Reports', href: routes.storyReports },
            // { name: 'List' },
          ]}
        />
        {/* className='bg-[#637381]' */}
        {/* <RoundedButton label='New Story Report' backgroundColor='#637381' onClick={handleAddRow} icon='mingcute:add-line' /> */}
      </div>

      {/* DataTable */}
      <DataTable
        tableName='Story Reports'
        deleteTitle='Story Report'
        mainCol='title'
        table={table}
        tableCols={STORY_REPORT_COLUMNS}
        tableData={dataFiltered}
        filters = {filters}
        resetFilters = {resetFilters}
        denseHeight={denseHeight}
        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
        notFound={notFound}
        handleFilters={handleFilters}
        handleResetFilters={handleResetFilters}
        // handleAddRow={handleAddRow}
        handleEditRow={handleEditRow}
        handleDeleteRow={handleDeleteRow}
        handleChangeState={handleChangeState}
        onSelectRow={handleSelectRow}
        hasSearch = {false}
        hasEdit={false}
        hasDelete={true}
        hasView={true} />
    </div>
  );
};

export default StoryReport;