export const PARENT_COLUMNS = [
    // { id: 'fullName', label: 'Name', width: '22%', isAvatar: true },
    { id: 'username', label: 'Name', width: '22%', isAvatar: true },
    { id: 'phoneNumber', label: 'Phone Number', width: '22%' },
    { id: 'email', label: 'Email', width: '24%' },
    // { id: 'role', label: 'Role', width: '18%' },
    // { id: 'status', label: 'Status', width: '18%',isList: true, list: ['Active','Inactive'] },
    { id: 'createdAt', label: 'Date of Join', width: '22%', isDate: true},
    { id: 'actions', width: '10%' },
];

export const PARENT_DUMMY_Data = [
    { id: '1', name: 'John Doe', phoneNumber: '123-456-7890', company: 'ABC Inc', role: 'Admin', status: 'Active' },
    { id: '2', name: 'Jane Doe', phoneNumber: '987-654-3210', company: 'XYZ Ltd', role: 'User', status: 'Inactive' },
    { id: '3', name: 'Jane Doe', phoneNumber: '987-654-3210', company: 'XYZ Ltd', role: 'User', status: 'Inactive' },
    { id: '4', name: 'Jane Doe', phoneNumber: '987-654-3210', company: 'XYZ Ltd', role: 'User', status: 'Inactive' },
    { id: '5', name: 'Jane Doe', phoneNumber: '987-654-3210', company: 'XYZ Ltd', role: 'User', status: 'Inactive' },
    { id: '6', name: 'Jane Dose', phoneNumber: '987-654-3210', company: 'XYZ Ltd', role: 'User', status: 'Inactive' },
];

export const PARENT_STATUS_OPTIONS = [
    { value: 'active', label: 'Active' },
    { value: 'pending', label: 'Pending' },
    { value: 'banned', label: 'Banned' },
    { value: 'rejected', label: 'Rejected' },
];