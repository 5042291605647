export const CHALLENGE_SUGGESTION_COLUMNS = [
    // { id:'coverImageUrl', label: 'Cover', width: '18%', isImage: true },
    { id: 'title', label: 'Title', width: '18%'},
    { id: 'startDate', label: 'startDate', width: '18%', isDate: true },
    { id: 'endDate', label: 'endDate', width: '18%', isDate: true},
    { id: 'standard', label: 'Standard', width: '18%', isList: true, list: [true, false] },
    { id: 'published', label: 'Published', width: '18%', isList: true, list: [true, false]},
    // { id: 'createdAt', label: 'Created Date', width: '25%', isDate: true},
    { id: 'actions', width: '10%' },
];

// const currentDate = new Date();
// const formattedDate = currentDate.toLocaleDateString('en-GB'); // 'en-GB' for dd/mm/yyyy format
const currentDate = '12/2/2024';
const formattedDate = currentDate;
export const CHALLENGE_SUGGESTION_DUMMY_DATA = [
    { id: '1', title: 'Upload avatar post', startDate: formattedDate,endDate: formattedDate, standard: true, published: true },
    { id: '2', title: 'Sports badge', startDate: formattedDate,endDate: formattedDate, standard: false, published: true },
    { id: '3', title: 'Travel badge', startDate: formattedDate,endDate: formattedDate, standard: false, published: true },
    { id: '4', title: 'Swimming badge', startDate: formattedDate,endDate: formattedDate, standard: false, published: true },
    { id: '5', title: 'Challenge', startDate: formattedDate,endDate: formattedDate, standard: false, published: true },
    { id: '6', title: 'Swim 5 meters', startDate: formattedDate,endDate: formattedDate, standard: false, published: true },
];

export const DIFFICULTY_LEVEL_OPTIONS = [
    { id: '1', value: 'Beginner' },
    { id: '2', value: 'Easy' },
    { id: '3', value: 'Intermediate' },
    { id: '4', value: 'Challenging' },
    { id: '5', value: 'Advanced' },
    { id: '6', value: 'Expert' },
    { id: '7', value: 'Master' },
    { id: '8', value: 'Insane' },
    { id: '9', value: 'Legendary' },
    { id: '10', value: 'Epic' },
];

export const MEDIA_TYPES_OPTIONS = [
    {id:'IMAGE', value: 'Image'},
    {id:'VIDEO', value: 'Video'}
];