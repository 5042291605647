import { paramCase } from '../utils/change-case';

// import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

// const MOCK_ID = _id[1];

// const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
  OVERVIEW: '/overview',
  STAFF: '/staff',
  MANAGEMENT: '/management',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/error/403',
  page404: '/error/404',
  page500: '/error/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
    //   details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
    //   details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    standard: {
      login: `${ROOTS.AUTH}/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        // edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        // details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        // edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        // details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        // edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        // details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        // edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        // details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        // details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        // edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        // details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        // edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
  // -------------------------------------------- overview routes --------------------------------------------
  overview: {
    dashboard: {
      root: `${ROOTS.OVERVIEW}/dashboard`,
      new: `${ROOTS.OVERVIEW}/dashboard/new`,
      list: `${ROOTS.OVERVIEW}/dashboard/list`,
      cards: `${ROOTS.OVERVIEW}/dashboard/cards`,
      profile: `${ROOTS.OVERVIEW}/dashboard/profile`,
      account: `${ROOTS.OVERVIEW}/dashboard/account`,
      edit: (id: string) => `${ROOTS.OVERVIEW}/dashboard/${id}/edit`,
      // demo: {
        // edit: `${ROOTS.OVERVIEW}/dashboard/${MOCK_ID}/edit`,
      // },
      create: `${ROOTS.OVERVIEW}/dashboard/create`
    },
    challengeSuggestions: {
      root: `${ROOTS.OVERVIEW}/challenge-suggestions`,
      new: `${ROOTS.OVERVIEW}/challenge-suggestions/new`,
      list: `${ROOTS.OVERVIEW}/challenge-suggestions/list`,
      cards: `${ROOTS.OVERVIEW}/challenge-suggestions/cards`,
      edit: (id: string) => `${ROOTS.OVERVIEW}/challenge-suggestions/${id}/edit`,
      // demo: {
        // edit: `${ROOTS.OVERVIEW}/challenge-suggestions/${MOCK_ID}/edit`,
      // },
      create: `${ROOTS.OVERVIEW}/challenge-suggestions/create`
    },
    storyReports:{
      root: `${ROOTS.OVERVIEW}/story-reports`,
    },
    chat:{
      root: `${ROOTS.OVERVIEW}/chat`,
    },
  },
  // -------------------------------------------- staff routes --------------------------------------------
  staff:{
    user: {
      root: `${ROOTS.STAFF}/users`,
      new: `${ROOTS.STAFF}/users/new`,
      list: `${ROOTS.STAFF}/users/list`,
      cards: `${ROOTS.STAFF}/users/cards`,
      profile: `${ROOTS.STAFF}/users/profile`,
      account: `${ROOTS.STAFF}/users/account`,
      edit: (id: string) => `${ROOTS.STAFF}/users/${id}/edit`,
      // demo: {
        // edit: `${ROOTS.STAFF}/users/${MOCK_ID}/edit`,
      // },
      create: `${ROOTS.STAFF}/users/create`
    },
    parent: {
      root: `${ROOTS.STAFF}/parents`,
      new: `${ROOTS.STAFF}/parents/new`,
      list: `${ROOTS.STAFF}/parents/list`,
      cards: `${ROOTS.STAFF}/parents/cards`,
      edit: (id: string) => `${ROOTS.STAFF}/parents/${id}/edit`,
      // demo: {
        // edit: `${ROOTS.STAFF}/parents/${MOCK_ID}/edit`,
      // },
      create: `${ROOTS.STAFF}/parents/create`
    },
    admin: {
      root: `${ROOTS.STAFF}/admins`,
      new: `${ROOTS.STAFF}/admins/new`,
      list: `${ROOTS.STAFF}/admins/list`,
      cards: `${ROOTS.STAFF}/admins/cards`,
      edit: (id: string) => `${ROOTS.STAFF}/admins/${id}/edit`,
      // demo: {
        // edit: `${ROOTS.STAFF}/admins/${MOCK_ID}/edit`,
      // },
      create: `${ROOTS.STAFF}/admins/create`
    },
    userReports:{
      root: `${ROOTS.STAFF}/user-reports`,
    },
  },
  // -------------------------------------------- management routes --------------------------------------------
  management:{
        category: {
          root: `${ROOTS.MANAGEMENT}/categories`,
          new: `${ROOTS.MANAGEMENT}/categories/new`,
          list: `${ROOTS.MANAGEMENT}/categories/list`,
          cards: `${ROOTS.MANAGEMENT}/categories/cards`,
          edit: (id: string) => `${ROOTS.MANAGEMENT}/categories/${id}/edit`,
          create: `${ROOTS.MANAGEMENT}/categories/create`,
        },
        challenge:{
          root: `${ROOTS.MANAGEMENT}/challenges`,
          new: `${ROOTS.MANAGEMENT}/challenges/new`,
          list: `${ROOTS.MANAGEMENT}/challenges/list`,
          cards: `${ROOTS.MANAGEMENT}/challenges/cards`,
          edit: (id: string) => `${ROOTS.MANAGEMENT}/challenges/${id}/edit`,
          // demo: {
            // edit: `${ROOTS.MANAGEMENT}/challenges/${MOCK_ID}/edit`,
          // },
          create: `${ROOTS.MANAGEMENT}/challenges/create`
        },
        challengeUserReports:{
          root: `${ROOTS.MANAGEMENT}/challenge-user-reports`,
        },
        activity:{
          root: `${ROOTS.MANAGEMENT}/activities`,
          new: `${ROOTS.MANAGEMENT}/activities/new`,
          list: `${ROOTS.MANAGEMENT}/activities/list`,
          cards: `${ROOTS.MANAGEMENT}/activities/cards`,
          edit: (id: string) => `${ROOTS.MANAGEMENT}/activities/${id}/edit`,
          // demo: {
            // edit: `${ROOTS.MANAGEMENT}/activities/${MOCK_ID}/edit`,
          // },
          create: `${ROOTS.MANAGEMENT}/activities/create`
        },
        badge:{
          root: `${ROOTS.MANAGEMENT}/badges`,
          new: `${ROOTS.MANAGEMENT}/badges/new`,
          list: `${ROOTS.MANAGEMENT}/badges/list`,
          cards: `${ROOTS.MANAGEMENT}/badges/cards`,
          edit: (id: string) => `${ROOTS.MANAGEMENT}/badges/${id}/edit`,
          create: `${ROOTS.MANAGEMENT}/badges/create`
        },
        award:{
          root: `${ROOTS.MANAGEMENT}/awards`,
          new: `${ROOTS.MANAGEMENT}/awards/new`,
          list: `${ROOTS.MANAGEMENT}/awards/list`,
          cards: `${ROOTS.MANAGEMENT}/awards/cards`,
          edit: (id: string) => `${ROOTS.MANAGEMENT}/awards/${id}/edit`,
          create: `${ROOTS.MANAGEMENT}/awards/create`
        },
        step:{
          root: `${ROOTS.MANAGEMENT}/steps`,
          new: `${ROOTS.MANAGEMENT}/steps/new`,
          list: `${ROOTS.MANAGEMENT}/steps/list`,
          cards: `${ROOTS.MANAGEMENT}/steps/cards`,
          edit: (id: string) => `${ROOTS.MANAGEMENT}/steps/${id}/edit`,
          create: `${ROOTS.MANAGEMENT}/steps/create`
        },
        settings:{
          root: `${ROOTS.MANAGEMENT}/settings`,
          new: `${ROOTS.MANAGEMENT}/settings/new`,
          list: `${ROOTS.MANAGEMENT}/settings/list`,
          cards: `${ROOTS.MANAGEMENT}/settings/cards`,
          edit: (id: string) => `${ROOTS.MANAGEMENT}/settings/${id}/edit`,
          create: `${ROOTS.MANAGEMENT}/settings/create`
        },
  },
};
