import { FC } from 'react';

interface IconProps {
  src: string;
  alt?: string;
}

const Icon: FC<IconProps> = ({ src, alt='' }) => {
  return (
    <img
      className=""
      src={src}
      alt={alt}/>
  );
};

export default Icon;