// AwardIcon.tsx

import React from 'react';

interface StepIconProps {
  fillColor?: string;
  strokeColor?: string;
}

const StepIcon: React.FC<StepIconProps> = ({ fillColor = '#FF922E',strokeColor = '#FE7E6C' }) => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.4832 16.7671C17.1164 17.0953 15.6895 17.0794 14.3303 16.7208C12.9712 16.3622 11.7221 15.6721 10.6951 14.7124C9.66804 13.7526 8.89506 12.5531 8.44535 11.2213C7.99564 9.88951 7.88325 8.46696 8.11824 7.08105C8.0825 7.11557 8.04477 7.14796 8.00524 7.17805C7.72523 7.39105 7.37524 7.47005 6.67524 7.62805L6.04024 7.77205C3.58024 8.32905 2.35024 8.60706 2.05723 9.54806C1.76523 10.4881 2.60324 11.4691 4.28024 13.4301L4.71424 13.9371C5.19024 14.4941 5.42923 14.7731 5.53623 15.1171C5.64323 15.4621 5.60723 15.8341 5.53523 16.5771L5.46923 17.2541C5.21623 19.8711 5.08924 21.1791 5.85524 21.7601C6.62123 22.3421 7.77323 21.8121 10.0752 20.7511L10.6722 20.4771C11.3262 20.1751 11.6532 20.0251 12.0002 20.0251C12.3472 20.0251 12.6742 20.1751 13.3292 20.4771L13.9242 20.7511C16.2272 21.8111 17.3792 22.3411 18.1442 21.7611C18.9112 21.1791 18.7842 19.8711 18.5312 17.2541L18.4832 16.7671Z" fill="url(#paint0_linear_3230_14622)"/>
<path opacity="0.32" d="M9.1527 5.408L8.8247 5.996C8.4647 6.642 8.2847 6.965 8.0047 7.178C8.0447 7.148 8.0817 7.116 8.1177 7.081C7.88265 8.46701 7.99504 9.88967 8.4448 11.2216C8.89455 12.5535 9.66763 13.7531 10.6948 14.7128C11.722 15.6726 12.9712 16.3627 14.3305 16.7212C15.6898 17.0797 17.1168 17.0954 18.4837 16.767L18.4637 16.577C18.3927 15.834 18.3567 15.462 18.4637 15.117C18.5707 14.773 18.8087 14.494 19.2857 13.937L19.7197 13.43C21.3967 11.47 22.2347 10.489 21.9417 9.548C21.6497 8.607 20.4197 8.328 17.9597 7.772L17.3237 7.628C16.6247 7.47 16.2747 7.391 15.9937 7.178C15.7137 6.965 15.5337 6.642 15.1737 5.996L14.8467 5.408C13.5797 3.136 12.9467 2 11.9997 2C11.0527 2 10.4197 3.136 9.1527 5.408Z" fill="url(#paint1_linear_3230_14622)"/>
<defs>
<linearGradient id="paint0_linear_3230_14622" x1="-3.40286" y1="12.2069" x2="6.38266" y2="28.173" gradientUnits="userSpaceOnUse">
<stop stopColor={strokeColor} />
<stop offset="1" stopColor={fillColor} />
</linearGradient>
<linearGradient id="paint1_linear_3230_14622" x1="3.47552" y1="7.15438" x2="14.0204" y2="21.4763" gradientUnits="userSpaceOnUse">
<stop stopColor={strokeColor} />
<stop offset="1" stopColor={fillColor} />
</linearGradient>
</defs>
</svg>
  );
};

export default StepIcon;
