import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Theme, SxProps } from '@mui/material/styles';

import EmptyContent from '../empty-content';

// ----------------------------------------------------------------------

type Props = {
  notFound: boolean;
  sx?: SxProps<Theme>;
};

export default function DataTableNoData({ notFound, sx }: Props) {
  return (
    <TableRow>
      {notFound ? (
        // sx={{py: 1, px: 2}} >> set padding out of no data container
        <TableCell colSpan={12} sx={{py: 1, px: 2}}>
          <EmptyContent
            filled
            title="No Data"
            sx={{
              // py: 10, //set padding from top and bottom in no data container
              height: '46vh', //set the height of the no data container
              ...sx,
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}
