import { getStorage, setStorage } from "../hooks/use-local-storage";

// Helper function to get and set authentication status in local storage
//because if refresh page state will return to default state/value so we need localStorage to save the current Authenticated state value
export const getAuthenticatedStatus = () => {
    // const storedStatus = localStorage.getItem("Authenticated");
    const storedStatus = getStorage("Authenticated");
    return storedStatus ? JSON.parse(storedStatus) : false;
};
  
 export const setAuthenticatedStatus = (status: boolean) => {
    // localStorage.setItem("Authenticated", JSON.stringify(status));
    setStorage("Authenticated", JSON.stringify(status));
};