import * as Yup from 'yup';

export const getAwardSchema = (isUpdate: boolean) => {
  const coverImageValidation = isUpdate ? Yup.mixed<any>().nullable() : Yup.mixed<any>().nullable().required('Cover Image is required');

  return Yup.object().shape({
    // title: Yup.string().required('Title (En) is required'),
    title: Yup.string()
    .required('Title (En) is required')
    .matches(/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]+$/, 'Title (En) must contain only English characters'),
    // titleAr: Yup.string().required('Title (Ar) is required'),
    titleAr: Yup.string()
    .required('Title (Ar) is required')
    .matches(/^[\u0600-\u06FF\s0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/, 'Title (Ar) must contain only Arabic characters'),
    description: Yup.string()
    .required('Description (En) is required')
    .matches(/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]+$/, 'Description (En) must contain only English characters'),
    // descriptionAr: Yup.string().required('Description (Ar) is required'),
    descriptionAr: Yup.string()
    .required('Description (Ar) is required')
    .matches(/^[\u0600-\u06FF\s0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/, 'Description (Ar) must contain only Arabic characters'),
    // .matches(/^[\u0600-\u06FF\s]+$/, 'Description (Ar) must contain only Arabic characters and spaces'),
    coverImage: coverImageValidation,
    // published: Yup.boolean(),
    awardCategory: Yup.number().required('Award Category is required').positive('Please select award category'),
  });
};