// StoryReportIcon.tsx

import React from 'react';

interface StoryReportIconProps {
  fillColor?: string;
  strokeColor?: string;
}

const StoryReportIcon: React.FC<StoryReportIconProps> = ({ fillColor = '#FF922E',strokeColor = '#637381' }) => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M21.6602 10.4395L20.6802 14.6195C19.8402 18.2295 18.1802 19.6895 15.0602 19.3895C14.5602 19.3495 14.0202 19.2595 13.4402 19.1195L11.7602 18.7195C7.59018 17.7295 6.30018 15.6695 7.28018 11.4895L8.26018 7.29952C8.46018 6.44952 8.70018 5.70952 9.00018 5.09952C10.1702 2.67952 12.1602 2.02952 15.5002 2.81952L17.1702 3.20952C21.3602 4.18952 22.6402 6.25952 21.6602 10.4395Z" 
fill={strokeColor}/>
<path d="M15.0603 19.3896C14.4403 19.8096 13.6603 20.1596 12.7103 20.4696L11.1303 20.9896C7.16034 22.2696 5.07034 21.1996 3.78034 17.2296L2.50034 13.2796C1.22034 9.30961 2.28034 7.20961 6.25034 5.92961L7.83034 5.40961C8.24034 5.27961 8.63034 5.16961 9.00034 5.09961C8.70034 5.70961 8.46034 6.44961 8.26034 7.29961L7.28034 11.4896C6.30034 15.6696 7.59034 17.7296 11.7603 18.7196L13.4403 19.1196C14.0203 19.2596 14.5603 19.3496 15.0603 19.3896Z" 
fill={strokeColor}/>
<path d="M17.4894 10.5098C17.4294 10.5098 17.3694 10.4998 17.2994 10.4898L12.4494 9.25978C12.0494 9.15978 11.8094 8.74978 11.9094 8.34978C12.0094 7.94978 12.4194 7.70978 12.8194 7.80978L17.6694 9.03978C18.0694 9.13978 18.3094 9.54978 18.2094 9.94978C18.1294 10.2798 17.8194 10.5098 17.4894 10.5098Z" 
fill={strokeColor}/>
<path d="M14.5592 13.8899C14.4992 13.8899 14.4392 13.8799 14.3692 13.8699L11.4592 13.1299C11.0592 13.0299 10.8192 12.6199 10.9192 12.2199C11.0192 11.8199 11.4292 11.5799 11.8292 11.6799L14.7392 12.4199C15.1392 12.5199 15.3792 12.9299 15.2792 13.3299C15.1992 13.6699 14.8992 13.8899 14.5592 13.8899Z" 
fill={strokeColor}/>
</svg>
  );
};

export default StoryReportIcon;
