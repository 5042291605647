// socket.ts

import { io } from 'socket.io-client';
import { httpURL, wsURL } from './config';
import { getStorage } from '../hooks/use-local-storage';

const URL = wsURL;
// const URL = httpURL;

const userToken = getStorage('Token');

export const socket = io(URL, {
  auth: {
    // token: '<YOUR_JWT_TOKEN>',
    token: userToken,
  },
});
