import { Controller, useFormContext } from 'react-hook-form';

import TextField, { TextFieldProps } from '@mui/material/TextField';
// import React from 'react';

// ----------------------------------------------------------------------

const styles = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '9999px',
    '& fieldset': {
        borderColor: 'black',
    },
    '&:hover fieldset': {
        borderColor: '#f87171',
    },
    '&.Mui-focused fieldset': {
        borderColor: '#f87171',
    },
  },
  '& label': {
      // color: 'red',
  },
  '& label.Mui-focused': {
      color: '#f87171',
  },
}

type Props = TextFieldProps & {
  name: string;
  autoComplete?: string; // Add autocomplete property to Props type
  // language?: 'arabic' | 'english'; // Add language property
};

export default function RHFRoundedTextField({ 
  name, 
  helperText = "", 
  type = 'text', 
  size = 'small', 
  fullWidth = true, 
  autoComplete, // Add autocomplete to props
  multiline = false,
  // language, // Add language to props
  ...other 
}: Props) {
  const { control } = useFormContext();
  // const [error, setError] = React.useState<string | null>(null);

    // Define regex patterns for Arabic and English content
    // const arabicPattern = /^[\u0600-\u06FF\s]+$/;
    // const englishPattern = /^[A-Za-z\s]+$/;
  
    // Select the appropriate pattern based on the selected language
    // const pattern = language === 'arabic' ? arabicPattern : englishPattern;

    // Custom validation function to check if the input matches the allowed language pattern
    // const validateLanguage = (value: string) => {
    //   if (!pattern.test(value)) {
    //     setError(
    //       language === 'arabic'
    //         ? 'Please enter Arabic characters only.'
    //         : 'Please enter English characters only.'
    //     );
    //   } else {
    //     setError(null);
    //   }
    // };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth = {fullWidth}
          type={type}
          size={size}
          multiline = {multiline}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
            // validateLanguage(event.target.value);
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          sx={styles}
          inputProps={{ autoComplete }} // Pass autocomplete directly to inputProps
          // Pass custom validation function to validate input language
          // rules={{ validate: validateLanguage }}
          {...other}
        />
      )}
    />
  );
}
