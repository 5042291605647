// use-socket.tsx

import { useEffect, useState } from "react";
import { socket } from "../socket";

export function useSocketIO() {
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    function onConnect() {
      // debugger;
      setIsConnected(true);
      console.log("connected", socket.id);
    }

    function onDisconnect() {
      // debugger;
      setIsConnected(false);
      console.log("disconnected", socket.id);
    }

    if (!socket) return;

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.onAny((event, ...args) => {
      // debugger;
      // console.log(Got event [${event}] with data: , JSON.stringify(args));
    });

    return () => {
      // debugger;
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.offAny();
    };
  }, []);

  return { socket, isConnected };
}
