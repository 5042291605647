// CategorySVG.tsx

import React from 'react';

interface CategorySVGProps {
  fillColor?: string;
  strokeColor?: string;
}

const CategorySVG: React.FC<CategorySVGProps> = ({ fillColor = '#FF922E',strokeColor = '#637381' }) => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M8.08329 12.958H1.50777C0.675552 12.958 0 13.6217 0 14.4393C0 15.2559 0.675552 15.9207 1.50777 15.9207H8.08329C8.91551 15.9207 9.59106 15.2559 9.59106 14.4393C9.59106 13.6217 8.91551 12.958 8.08329 12.958Z" 
        fill={fillColor}/>
      <path opacity="0.4" d="M20.0001 3.37867C20.0001 2.56214 19.3246 1.89844 18.4934 1.89844H11.9179C11.0857 1.89844 10.4102 2.56214 10.4102 3.37867C10.4102 4.1963 11.0857 4.86 11.9179 4.86H18.4934C19.3246 4.86 20.0001 4.1963 20.0001 3.37867Z" 
        fill={fillColor}/>
      <path d="M6.87774 3.37856C6.87774 5.24523 5.33886 6.75821 3.43887 6.75821C1.53999 6.75821 0 5.24523 0 3.37856C0 1.51298 1.53999 0 3.43887 0C5.33886 0 6.87774 1.51298 6.87774 3.37856Z" 
        fill={fillColor}/>
      <path d="M20.0008 14.3992C20.0008 16.2648 18.4619 17.7777 16.5619 17.7777C14.663 17.7777 13.123 16.2648 13.123 14.3992C13.123 12.5325 14.663 11.0195 16.5619 11.0195C18.4619 11.0195 20.0008 12.5325 20.0008 14.3992Z" 
        fill={fillColor}/>
    </svg>
  );
};

export default CategorySVG;
