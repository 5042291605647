import axios, { AxiosError } from 'axios';

const handleAxiosErrors = (error: AxiosError) => {

  // console.error('Error fetching category data:', error);

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.error('Error response status:', error.response.status);
    if (error.response.status === 401) {// handle 401 error (Unauthorized)
      // Redirect to login or handle unauthorized access
      throw new Error('Unauthorized'); // this will throwed to the page that called this interceptor to catch code
    }
    else{// handle errors [400, 500, ...]
      throw error; // this will throwed to the page that called this interceptor to catch code
    }

    // Handle other error status codes as needed
  } else if (error.request) {
    // The request was made but no response was received >> 
    // 1.handle no internet connection (error.message == "Network Error")
    // 2.slow internet connection
    // console.error('No response received:', error.request);
    throw error; // this will throwed to the page that called this interceptor to catch code
  } else { // error{message, stack}
    // Something happened in setting up the request that triggered an Error (error in axios request)
    // console.error('Error setting up the request:', error.message);
    throw error; // this will throwed to the page that called this interceptor to catch code
  }

  // You can also throw the error again to let the calling code handle it
  // throw error;
};

// Attach the interceptor
axios.interceptors.response.use(undefined, handleAxiosErrors);

export default handleAxiosErrors;
