import React from 'react';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';

import { Card } from '@mui/material';
import { routes } from '../../../routes/routes';
import { useSocketIO } from '../../../utils/web-socket/use-socket';

// import RoundedButton from '../../../components/common/button/rounded-button';

// ----------------------------------------------------------------------

interface DashboardProps {
}

// ----------------------------------------------------------------------

const Dashboard: React.FC<DashboardProps> = () => {

  useSocketIO();

  return (
    <div>
      {/* Bread Crumb + Create Button */}
      <div className='flex items-center justify-between pb-3 h-12'>
        {/* <h2>Overview / Dashboard</h2> */}
        <CustomBreadcrumbs
          // heading="List"
          links={[
            { name: 'Overview', href: routes.dashboard },
            { name: 'Dashboard', href: routes.dashboard },
            // { name: 'List' },
          ]}
        />
        {/* className='bg-[#637381]' */}
        {/* <RoundedButton label='New Category' className='hidden' backgroundColor='#637381' icon='mingcute:add-line' onClick={function (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
          throw new Error('Function not implemented.');
        } } /> */}
      </div>
      
      <div className='flex flex-col'>
        <div className='flex flex-row h-44 gap-5'>
          <Card className='h-full w-1/3'>

          </Card>
          <Card className='h-full w-1/3'>

          </Card>
          <Card className='h-full w-1/3'>

          </Card>
        </div>
        <div className='flex flex-row h-80 gap-5 mt-5'>
          <Card className='h-full w-1/3'>

          </Card>
          <Card className='h-full w-2/3'>

          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;