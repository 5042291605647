import React, { useEffect, useRef, useState } from 'react';
import { Conversation, Message } from '../../../types/chat';
import { getStorage } from '../../../hooks/use-local-storage';
import { Box, IconButton } from '@mui/material';
import ChatUser from './chat-user';
import noDataIMG from '../../../assets/icons/chat.svg';
import ChatBubble from './chat-bubble';
import { useSocketIO } from '../../../utils/web-socket/use-socket';
import Iconify from '../../minimal/iconify';
import axios from 'axios';
import { CHAT_HOST } from '../../../config-global';
import { handleErrors } from '../../../handlers/axiosErrorsHandler';
import { enqueueSnackbar } from 'notistack';

const MESSAGES_EVENT = 'messages'; // this event is responsible for every new message sent to the user
const ERRORS_EVENT = 'errors'; // this event is responsible for all errors that happen during the socket connection


interface ChatWindowProps {
    currentConversation?: Conversation;
    chatMessages: Message[];
}

const ChatWindow: React.FC<ChatWindowProps> = ({ currentConversation, chatMessages }) => {
    const { isConnected, socket } = useSocketIO();
    
    const [messages, setMessages] = useState<Message[]>([]);
    const [newMessage, setNewMessage] = useState<Message>({
        text: '',
        createdAt: '',
        senderId: '',
        sender: { id: '', name: '', imageURL: '' }
    });
    const [fileURL, setFileURL] = useState<string | null>(null); // State for holding file URL
    const [page, setPage] = useState<number>(1); // Page number for pagination
    const [loadingMoreMessages, setLoadingMoreMessages] = useState<boolean>(false); // Loading state for more messages

    const currentUser = JSON.parse(getStorage('User'));
    const messagesEndRef = useRef<HTMLDivElement>(null); // Create a ref for the messages container
    const fileInputRef = useRef<HTMLInputElement>(null); // Create a ref for the file input element
    const messagesContainerRef = useRef<HTMLDivElement>(null); // Create a ref for the messages container to detect scroll

    useEffect(() => {
        setMessages(chatMessages);
        setPage(1); // Reset page number when conversation changes
        // goDown();
        if (socket) {
            socket?.on(MESSAGES_EVENT, (data) => {
                console.log({ data });
                debugger;
                // Update messages with the new message received from the socket
                setMessages(prevMessages => [...prevMessages, data]);
            });
            socket?.on(ERRORS_EVENT, (data) => {
                console.log({ data });
            });
          }
      
          return () => {
            if (socket) {
              socket.off(MESSAGES_EVENT);
              socket.off(ERRORS_EVENT);
            }
          };
      
    }, [chatMessages]);

    // when update messages array >> go down to end
    useEffect(() => {
        // Scroll to the bottom whenever messages change
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);
    
    const handleSendMessage = () => {
        // if (newMessage?.text.trim() !== '') {
        //     // type the new message into array of messages (locally) to show the message i sended
        //     setMessages([...messages, newMessage!!]);
        //     //reset message object after sending message
        //     setNewMessage({ text: '', createdAt: '', senderId: '', sender: {id: '', name: '', imageURL: ''}});
        //     // goDown();
        // }
        if (newMessage?.text.trim() !== '' || fileURL) {
            if (socket) {
                socket.emit(
                  'message',
                  {
                    text: newMessage.text,
                    conversationId: currentConversation?.id,
                    // if your message has image, video, audio, or any other file then pass the file url like below
                    fileURL: fileURL,
                  },
                  (ack: unknown) => {
                    console.log({ ack });
                    // type the new message into array of messages (locally) to show the message i sended
                    setMessages([...messages, newMessage!!]);
                    //reset message object after sending message
                    setNewMessage({
                        text: '',
                        createdAt: '',
                        senderId: '',
                        sender: { id: '', name: '', imageURL: '' }
                    });
                    setFileURL(null);
                  },
                );
            }
        }      
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            // Handle the selected file (e.g., upload it, add it to the chat, etc.)
            console.log(file);
            const reader = new FileReader();
            reader.onload = () => {
                setFileURL(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOpenFilePicker = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();  // Prevent default form submission
            handleSendMessage();
        }
    };

    // const goDown = () => {
    //     // Scroll to the bottom whenever messages change
    //     if (messagesEndRef.current) {
    //         messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    // }

    const handleScroll = async () => {
        if (messagesContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
            if (scrollTop === 0 && !loadingMoreMessages) {
                setLoadingMoreMessages(true);
                try {
                    const newPage = page + 1;
                    const response = await axios.get(`${CHAT_HOST}/conversations/${currentConversation?.id}/messages?page=${newPage}&perPage=10&isFirstFetch=${newPage === 1}&withoutUnread=true`, {
                        headers: {
                            'Authorization': `Bearer ${getStorage('Token')}`,
                            'x-locale': 'en',
                        }
                    });
                    if (response.status === 200) {
                        const newMessages = response.data;
                        setMessages(prevMessages => [...newMessages, ...prevMessages]);
                        setPage(newPage);
                        setLoadingMoreMessages(false);
                    }
                } catch (error) {
                    // handleErrors(error, enqueueSnackbar);
                    setLoadingMoreMessages(false);
                }
            }
        }
    };

    return (
        (messages.length > 0 ? 
        <div className="chat-window flex flex-col justify-between h-full">
            <ChatUser fullname={currentConversation?.info?.name!!} avatar={currentConversation?.info?.imageURL} />
            <hr className="mt-4" />
            <div className="messages overflow-auto p-5" style={{height:'65vh', maxHeight:'65vh'}}>
                {messages.map((message, index) => (
                    <ChatBubble key={index} message={message} currentUser={currentUser} />
                ))}
                <div ref={messagesEndRef} /> {/* This div will be used as the reference point to scroll to */}
            </div>
            <div className="input-area flex flex-row justify-between" style={{borderTop: "1px solid rgba(20, 20, 20, 0.16)"}}>
                <input 
                    className="w-full pl-5 focus:outline-none"
                    type="text" 
                    placeholder="Type a message..." 
                    value={newMessage?.text}
                    onChange={(e) => setNewMessage({text: e.target.value, createdAt: new Date().toISOString(), 
                        senderId: currentUser.id, sender: {id: currentUser.id, name: currentUser.fullName, imageURL: '' }})}
                    onKeyDown={handleKeyDown}
                />
                <IconButton onClick={handleOpenFilePicker} className="h-12 w-12">
                    <Iconify icon="solar:gallery-add-bold" />
                    {/* <img src={noDataIMG} alt="choose pic" /> */}
                </IconButton>
                {/* <IconButton onClick={handleSendAudioMessage} className="h-12 w-12">
                    <Iconify icon="solar:microphone-bold" /> */}
                    {/* <img src={noDataIMG} alt="record audio" /> */}
                {/* </IconButton> */}
                <button onClick={handleSendMessage} className="h-12 w-24">Send</button>
            </div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </div>
        : 
        <div className='flex flex-col justify-center items-center h-full text-lg'>
            <Box
                component="img"
                alt="empty content"
                src={noDataIMG}
                sx={{ width: 1, maxWidth: 160 }}/>
            Please select user to chat with! 
        </div>
        )
    );
};

export default ChatWindow;
