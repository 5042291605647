import { FC } from 'react';
import { Avatar } from '@mui/material';
// import  Label  from '../../minimal/label';
import { ASSETS_API, ASSETS_API_PREFIX } from '../../../config-global';

interface ChatUserProps {
    fullname: string;
    avatar?: string;
}

const ChatUser: FC<ChatUserProps> = ({ 
    fullname, 
    avatar
  }) => {
    return(
        // p-5
        <div className='flex items-center px-5 pt-5'>
            <Avatar alt='' src={avatar === null || avatar === '' ? '' : ASSETS_API + ASSETS_API_PREFIX + avatar} sx={{ mr: 2 }} />
            {/* <Label>{ fullname }</Label> */}
            <span>{ fullname }</span>
        </div>
    );
};

export default ChatUser;