import React from 'react';

interface UserIconProps {
  fillColor?: string;
  strokeColor?: string;
}

const UserIcon: React.FC<UserIconProps> = ({ fillColor = '#FF922E',strokeColor = '#637381' }) => {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9488 10.54C7.49884 10.54 4.58789 11.1038 4.58789 13.2795C4.58789 15.4562 7.51765 16.0001 10.9488 16.0001C14.3988 16.0001 17.3098 15.4364 17.3098 13.2606C17.3098 11.084 14.38 10.54 10.9488 10.54Z" 
        fill={fillColor}/>
      <path opacity="0.4" d="M10.9495 8.46703C13.2856 8.46703 15.1588 6.58307 15.1588 4.23351C15.1588 1.88306 13.2856 0 10.9495 0C8.61342 0 6.74023 1.88306 6.74023 4.23351C6.74023 6.58307 8.61342 8.46703 10.9495 8.46703Z"
        fill={fillColor}/>
      <path opacity="0.4" d="M20.0886 5.21972C20.693 2.84225 18.9209 0.707031 16.6645 0.707031C16.4192 0.707031 16.1846 0.734048 15.9554 0.779976C15.9249 0.787181 15.8909 0.80249 15.873 0.829507C15.8524 0.863728 15.8676 0.909656 15.89 0.939375C16.5678 1.89577 16.9573 3.06018 16.9573 4.31016C16.9573 5.5079 16.6001 6.62459 15.9733 7.55126C15.9088 7.64672 15.9661 7.7755 16.0798 7.79531C16.2374 7.82323 16.3986 7.83763 16.5634 7.84214C18.2064 7.88536 19.6811 6.82181 20.0886 5.21972Z" 
        fill={fillColor}/>
      <path d="M21.8094 10.8165C21.5086 10.1717 20.7824 9.7295 19.6783 9.51246C19.1572 9.38459 17.747 9.20447 16.4352 9.22879C16.4155 9.23149 16.4048 9.245 16.403 9.254C16.4003 9.26661 16.4057 9.28823 16.4316 9.30173C17.0378 9.60342 19.3811 10.9155 19.0865 13.6829C19.074 13.8027 19.1698 13.9063 19.2888 13.8883C19.8655 13.8054 21.3492 13.4848 21.8094 12.4861C22.0637 11.9584 22.0637 11.3451 21.8094 10.8165Z" 
        fill={fillColor}/>
      <path opacity="0.4" d="M6.04508 0.779976C5.81675 0.733147 5.58126 0.707031 5.33592 0.707031C3.0795 0.707031 1.3075 2.84225 1.91279 5.21972C2.31931 6.82181 3.79403 7.88536 5.4371 7.84214C5.60185 7.83763 5.76392 7.82232 5.92061 7.79531C6.03433 7.7755 6.09164 7.64672 6.02717 7.55126C5.40039 6.62368 5.04312 5.5079 5.04312 4.31016C5.04312 3.05928 5.43352 1.89486 6.11134 0.939375C6.13283 0.909656 6.14894 0.863728 6.12745 0.829507C6.10954 0.80159 6.07642 0.787181 6.04508 0.779976Z" 
        fill={fillColor}/>
      <path d="M2.32156 9.5127C1.21752 9.72973 0.492248 10.1719 0.191392 10.8167C-0.0637974 11.3453 -0.0637974 11.9586 0.191392 12.4872C0.651629 13.4851 2.13531 13.8066 2.71195 13.8885C2.83104 13.9065 2.92595 13.8038 2.91342 13.6832C2.61883 10.9167 4.9621 9.60456 5.56918 9.30287C5.59425 9.28846 5.59962 9.26775 5.59694 9.25424C5.59515 9.24523 5.5853 9.23173 5.5656 9.22992C4.25294 9.20471 2.84358 9.38482 2.32156 9.5127Z" 
        fill={fillColor}/>
    </svg>
  );
};

export default UserIcon;
