// DataTable.tsx
import React from 'react';

// import { Card, Table, TableBody, TableContainer, Tooltip, IconButton, TableCell, Checkbox, Avatar, ListItemText } from '@mui/material';
import { Card, Table, TableBody, TableContainer } from '@mui/material';

// import { TableHeadCustom, TableSelectedAction, TablePaginationCustom, TableEmptyRows, TableNoData } from '../../minimal/table';
// import Scrollbar from '../../minimal/scrollbar';
// import Iconify from '../../minimal/iconify';
// import Label from '../../minimal/label';
// import Image from '../../minimal/image';

// import DataTableRow from './data-table-row';
// import { DataTableToolbar, DataTableFiltersResult, DataTableHead, DataTableRow, DataTableEmptyRows, DataTableNoData, DataTablePagination } from '.';
import { DataTableToolbar, DataTableFiltersResult, DataTableHead, DataTableRow, DataTableNoData, DataTablePagination } from '.';

// import { ASSETS_API, ASSETS_API_PREFIX } from '../../../config-global';
// import noodyIMG from '../../../assets/images/noody.svg'
// import { ImageRounded } from '@mui/icons-material';

const TableHeadStyles = {
  // Add backgroundColor for table head
  backgroundColor: '#DFE3E8',
  // color: '#FFFFFF',
  /* Apply sticky positioning to the table head */
  position: 'sticky!important', 
  top: 0, 
  zIndex: 1,
}

const TablePaginationStyles = {
  // Set backgroundColor for table pagination
  backgroundColor: '#DFE3E8',
}

interface DataTableProps {
  tableName: string;
  deleteTitle: string;
  mainCol: string;
  table: any; // Replace 'any' with the actual type for table state
  tableCols: any[]; // Replace 'any' with the actual type for table head
  tableData: any[]; // Adjust the type according to your data structure
  filters?: any; // Adjust the type according to your data structure
  resetFilters?:boolean;
  denseHeight: number;
  emptyRows?: number;
  notFound: boolean;
  handleFilters:(name: string,value: any) => void;
  handleResetFilters:() => void;
  // handleAddRow:() => void;
  handleEditRow: (id: string, row: any) => void;
  handleDeleteRow: (id: string) => void;
  handleChangeState: (id: string, state: boolean) => void;
  handleApprovalState?: (id: string, approval: string) => void;
  onSelectRow?: (id: string, selectionMode: string) => void;
  // onSelectAllRows ,
  hasSearch?: boolean;
  hasEdit?: boolean;
  hasDelete?: boolean;
  hasView?: boolean;
  selectionMode?: 'single' | 'multiple'; // Add new prop for selection mode
  hasSelections?: boolean;
}

const DataTable: React.FC<DataTableProps> = ({
  tableName,
  deleteTitle,
  mainCol,
  table,
  tableCols,
  tableData,
  filters,
  resetFilters,
  denseHeight,
  emptyRows,
  notFound,
  handleFilters,
  handleResetFilters,
  // handleAddRow,
  handleEditRow,
  handleDeleteRow,
  handleChangeState,
  handleApprovalState,
  onSelectRow,
  hasSearch = true,
  hasEdit = true,
  hasDelete = true,
  hasView = false,
  selectionMode = 'single', // Default to single selection mode
  hasSelections = false,
}) => {
  // console.log('tableData:', tableData);

  // const handleSelectRow = (selectedId: string, mode: string) => {
  //   if (mode === 'single') {
  //     // Clear all other selections
  //     const newSelected = [selectedId];
  //     // updateTable({ ...table, selected: newSelected });
  //   } else {
  //     // Toggle selection without affecting other rows
  //     const selectedIndex = table.selected.indexOf(selectedId);
  //     let newSelected: string[] = [];
  
  //     if (selectedIndex === -1) {
  //       // If the selected row is not already selected, add it to the selected array
  //       newSelected = newSelected.concat(table.selected, selectedId);
  //     } else if (selectedIndex === 0) {
  //       // If the selected row is the first selected row, remove it from the array
  //       newSelected = newSelected.concat(table.selected.slice(1));
  //     } else if (selectedIndex === table.selected.length - 1) {
  //       // If the selected row is the last selected row, remove it from the array
  //       newSelected = newSelected.concat(table.selected.slice(0, -1));
  //     } else if (selectedIndex > 0) {
  //       // If the selected row is in the middle, remove it from the array
  //       newSelected = newSelected.concat(
  //         table.selected.slice(0, selectedIndex),
  //         table.selected.slice(selectedIndex + 1)
  //       );
  //     }
  
  //     // Update the selected state
  //     // updateTable({ ...table, selected: newSelected });
  //   }
  // };
  

  return (
    <div>
      {/* <Container maxWidth={'xl'}> */}
      {/* Rounded Table Corners */}
      {/* <Card sx={{borderTopLeftRadius: 20, borderTopRightRadius: 20}}> */}
      {/* <Card sx={{borderRadius: 7}}> */}
      <Card sx={{borderRadius: '2rem'}}>
        {/* table toolbar for table name and search text-field and export options */}
        <DataTableToolbar
          tableName={tableName}
          filters={filters}
          onFilters={handleFilters}
          hasSearch={hasSearch}/>
  
        {/* reset filters bar */}
        {resetFilters && (
          <DataTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            onResetFilters={handleResetFilters}
            results={tableData.length}
            // sx={{ p: 2.5, pt: 0 }}/>
            sx={{ p: 2.5, py: 0 }}/>
        )}
            
        {/* table */}
        {/* overflow: 'unset', */}
        <TableContainer sx={{ position: 'relative', overflow: 'auto', maxHeight: '57vh', minHeight: '57vh' }}>
          {/* <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                tableData?.map((row: any) => row.id)
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={() => handleDeleteRow(table.selected[0])}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          /> */}
  
          {/* <Scrollbar> make table goes out of screen when side navbar opened */}
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              {/* table head (table columns) */}
              <DataTableHead
                order={table.order}
                orderBy={table.orderBy}
                headLabel={tableCols}
                rowCount={tableData.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    tableData.map((row: any) => row.id)
                  )
                }
                selectionMode={selectionMode}
                sx={TableHeadStyles}
              />
  
              {/* table body */}
              <TableBody>
                {tableData.length > 0 && tableData
                  .slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  )
                  .map((row: any, index: number) => (
                    <DataTableRow key={row.id || index} // Use row.id if available, otherwise use index
                    row={row}
                    selected={table.selected.includes(row.id)}
                    // onSelectRow={() => table.onSelectRow(row.id, selectionMode)}
                    onSelectRow={() => onSelectRow?.(row.id, selectionMode)}
                    onDeleteRow={() => handleDeleteRow(row.id)}
                    onEditRow={() => handleEditRow(row.id, row)}
                    onListCellClick={(state: boolean) => handleChangeState(row.id, state)}
                    onListOptionClick={(approval: string) => handleApprovalState?.(row.id, approval)}
                    columns={tableCols}
                    deleteTitle={deleteTitle}
                    mainCol={mainCol} 
                    selectionMode={selectionMode}
                    hasSelections={hasSelections}
                    hasEdit={hasEdit}
                    hasDelete={hasDelete}
                    hasView={hasView}/>
                  ))}
  
                {/* Empty Rows if data is less than the number of rows per page */}
                {/* <DataTableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows} /> */}
  
                {/* when table-list (tableData) is empty >> no data found */}
                <DataTableNoData notFound={notFound} />
              </TableBody>
            </Table>
                        
          {/* </Scrollbar> */}
        </TableContainer>
                  
        <DataTablePagination
          count={tableData.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense} 
          sx={TablePaginationStyles}/>
      </Card>
      {/* </Container> */}
  </div>
  );
};

export default DataTable;
