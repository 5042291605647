import { setAuthenticatedStatus } from '../interceptors/auth';
import handleAxiosErrors from '../interceptors/axios-errors';

// Enumerate error types
const ERROR_TYPES = {
  UNAUTHORIZED: 'Unauthorized',
  UNAUTHORIZED_ACCESS: 'Unauthorized access',
  OTHER_ERROR: 'Other error',
  NETWORK_ERROR: 'Network Error',
};

// Define error message constants
const ERROR_MESSAGES = {
  UNAUTHORIZED: 'Unauthorized',
  UNAUTHORIZED_ACCESS: 'Error setting up the request',
  NETWORK_ERROR: 'No internet connection. Please check your connection and try again.',
};

// Function to handle 401 error (Unauthorized)
const handleUnauthorized = async (enqueueSnackbar: any, setAuthenticated: any, variantType: string) => {
  enqueueSnackbar(ERROR_MESSAGES.UNAUTHORIZED, { variant: variantType });
  // Wait for 2 seconds before executing the rest of the code
  await new Promise(resolve => setTimeout(resolve, 2000));
  // Redirect to login or handle unauthorized access  
  // change Authenticated state value in localStorage (for refresh action) because state return to default value if refresh page
  setAuthenticatedStatus(false);
  // change global Authenticated state current value
  setAuthenticated(false);    
};

// Function to handle other errors (handle errors [400, 500, ...] and no internet connection and error in setting-up request)
const handleOtherErrors = (error:any , enqueueSnackbar: any, variantType: string) => {
  if (error.response) { // handle errors [400, 500, ...]
    enqueueSnackbar(`${error.response?.data?.errors}`, { variant: variantType });
  } else{ 
    if (error.message === ERROR_TYPES.NETWORK_ERROR) { // handle no internet connection, or slow internet connection
      enqueueSnackbar(ERROR_MESSAGES.NETWORK_ERROR, { variant: variantType });
    } else if (error.message === ERROR_TYPES.UNAUTHORIZED_ACCESS) { // handle error in setting-up request
      enqueueSnackbar(ERROR_MESSAGES.UNAUTHORIZED_ACCESS, { variant: variantType });
    } else { // handle other errors
      enqueueSnackbar(`${error.message}`, { variant: variantType });
    }
  }
};

export const handleErrors = async (
  error: any,
  setAuthenticated: (value: boolean) => void,
  enqueueSnackbar: any,
  variant: string = 'error',
) => {
  try {
    // Handle the error using (axios-errors) interceptor
    await handleAxiosErrors(error);
    // Handle other specific error handling if needed
  } catch (interceptorError: any) {
    // Handle specific errors thrown by (axios-errors) interceptor
    switch(interceptorError.message){
      case ERROR_TYPES.UNAUTHORIZED: // handle 401 error (Unauthorized)
        await handleUnauthorized(enqueueSnackbar, setAuthenticated, variant);
        break;
      default: // handle other errors (handle errors [400, 500, ...] and no internet connection and error in setting-up request)
        handleOtherErrors(interceptorError, enqueueSnackbar, variant);
        break;
    } 
  }
};
