export const CATEGORY_COLUMNS = [
    { id:'coverImageUrl', label: 'Cover', width: '10%', isImage: true },
    { id: 'title', label: 'Title', width: '30%'},
    // { id: 'numberChallengeWinBadge', label: 'Challenge Win Badge', width: '25%' },
    // { id: 'standard', label: 'Standard', width: '25%', isList: true},
    { id: 'createdAt', label: 'Created Date', width: '30%', isDate: true},
    { id: 'published', label: 'Published', width: '15%', isList: true, list: [true, false]},
    { id: 'actions', width: '15%' },
];

export const CATEGORY_DUMMY_Data = [
    { id: '1', title: 'General', numberChallengeWinBadge: 0, standard: true, published: true },
    { id: '2', title: 'Travel', numberChallengeWinBadge: 2, standard: false, published: true },
    { id: '3', title: 'Swimming', numberChallengeWinBadge: 1, standard: false, published: true },
    { id: '4', title: 'Games', numberChallengeWinBadge: 1, standard: false, published: true },
    { id: '5', title: 'Space', numberChallengeWinBadge: 1, standard: false, published: true },
    { id: '6', title: 'Study', numberChallengeWinBadge: 1, standard: false, published: true },
];

export const CATEGORY_OPTIONS = [
    { id: '1', value: 'General' },
    { id: '2', value: 'Travel' },
    { id: '3', value: 'Swimming' },
    { id: '4', value: 'Games' },
];

