import React from 'react';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';

import { Card } from '@mui/material';
import { routes } from '../../../routes/routes';

// ----------------------------------------------------------------------

interface CreateChallengeSuggestionProps {
}

// ----------------------------------------------------------------------

const CreateChallengeSuggestion: React.FC<CreateChallengeSuggestionProps> = () => {

    return(
        <div>
            {/* Bread Crumb */}
            <div className='flex items-center justify-between pb-3 h-12'>
              {/* <h2>Overview / Challenge Suggestions / Create</h2> */}
              <CustomBreadcrumbs
                // heading="List"
                links={[
                  { name: 'Overview', href: routes.challengeSuggestions },
                  { name: 'Challenge Suggestions', href: routes.challengeSuggestions },
                  { name: 'Create' },
                ]}
              />
            </div>
            <div className='flex flex-row min-h-96'>
                <h2 className='uppercase font-bold w-1/2'>Info</h2>
                <Card className='w-1/2'>
                    
                </Card>
            </div>
        </div>
    )
};

export default CreateChallengeSuggestion;