import React, { useCallback, useState } from 'react';

import isEqual from 'lodash/isEqual';

// import RoundedButton from '../../../components/common/button/rounded-button';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';
import { 
  useTable,  
  emptyRows,
  getComparator,
} from '../../../components/minimal/table';

// import { IChallengeItem, IChallengeTableFilters, IChallengeTableFilterValue } from '../../../types/challenge';
import { IChallengeTableFilters, IChallengeTableFilterValue } from '../../../types/challenge';
// import {CHALLENGE_SUGGESTION_COLUMNS, CHALLENGE_SUGGESTION_DUMMY_DATA} from '../../../consts/challenge-suggestions';
import {CHALLENGE_SUGGESTION_COLUMNS} from '../../../consts/challenge-suggestions';

import DataTable from '../../../components/common/data-table/data-table';

import { applyFilter } from '../../../utils/data-table-filter';
import { routes } from '../../../routes/routes';

// import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

interface ChallengeSuggestionProps {
}

// ----------------------------------------------------------------------

// const currentDate = new Date();
// const formattedDate = currentDate.toLocaleDateString('en-GB'); // 'en-GB' for dd/mm/yyyy format

// const dummyData: IChallengeItem[] = [
//   { id: '1', title: 'Upload avatar post', startDate: formattedDate,endDate: formattedDate, standard: true, published: true },
//   { id: '2', title: 'Sports badge', startDate: formattedDate,endDate: formattedDate, standard: false, published: true },
//   { id: '3', title: 'Travel badge', startDate: formattedDate,endDate: formattedDate, standard: false, published: true },
//   { id: '4', title: 'Swimming badge', startDate: formattedDate,endDate: formattedDate, standard: false, published: true },
//   { id: '5', title: 'Challenge', startDate: formattedDate,endDate: formattedDate, standard: false, published: true },
//   { id: '6', title: 'Swim 5 meters', startDate: formattedDate,endDate: formattedDate, standard: false, published: true },
// ];

// const dummyData: IChallengeItem[] = CHALLENGE_SUGGESTION_DUMMY_DATA;

const defaultFilters: IChallengeTableFilters = {
  title: '',
};

// ----------------------------------------------------------------------

const ChallengeSuggestion: React.FC<ChallengeSuggestionProps> = () => {
  const table = useTable();

  const [tableData, setTableData] = useState([]);
  // const [tableData, setTableData] = useState(dummyData);
  
  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const resetFilters = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && resetFilters) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IChallengeTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  // const navigate = useNavigate(); // Hook for navigation

  // const handleAddRow = () =>{
  //   // Perform any necessary logout logic
  //   navigate('/management/challenge-suggestions/create');
  // }

  const handleEditRow = useCallback(
    (id: string) => {
      // navigate(routes.categoriesCreate);
    },
    []
  );

  const handleDeleteRow = useCallback(
    (id: string) => {
      const deleteRow = tableData.filter((row:any) => row.id !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  // const handleDeleteRows = useCallback(() => {
  //   const deleteRows = tableData.filter((row:any) => !table.selected.includes(row.id));
  //   setTableData(deleteRows);

  //   table.onUpdatePageDeleteRows({
  //     totalRows: tableData.length,
  //     totalRowsInPage: dataInPage.length,
  //     totalRowsFiltered: dataFiltered.length,
  //   });
  // }, [dataFiltered.length, dataInPage.length, table, tableData]);

  const handleChangeState = useCallback(
    (id: string) => {
    return null;
  },[]);

    // State to hold selected rows
    const [selectedRows, setSelectedRows] = useState<any>([]);

    // Function to handle row selection
    const handleSelectRow = useCallback((id: string, selectionMode: string)=>{
      // Check if the row is already selected
      const selectedIndex = selectedRows.indexOf(id);
      let newSelected:any = [];
  
      table.onSelectRow(id);
      if (selectedIndex === -1) {
        // If not selected, add to the selection
        newSelected = newSelected.concat(selectedRows, id);
      } else if (selectedIndex === 0) {
        // If first element is selected, remove it
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        // If last element is selected, remove it
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        // If middle element is selected, remove it
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
        
      }
  
      // Update the selected rows state
      setSelectedRows(newSelected);
    },
    [table, selectedRows]
    );

  return (
    <div>
      {/* Bread Crumb + Create Button */}
      <div className='flex items-center justify-between pb-3 h-12'>
        {/* <h2>Overview / Challenge Suggestions</h2> */}
        <CustomBreadcrumbs
          // heading="List"
          links={[
            { name: 'Overview', href: routes.challengeSuggestions },
            { name: 'Challenge Suggestions', href: routes.challengeSuggestions },
            // { name: 'List' },
          ]}
        />
        {/* className='bg-[#637381]' */}
        {/* <RoundedButton label='New Challenge' backgroundColor='#637381' onClick={handleAddRow} icon='mingcute:add-line' /> */}
      </div>
      
      {/* DataTable */}
       <DataTable
        tableName='Challenge Suggestions'
        deleteTitle='Challenge Suggestion'
        mainCol='title'
        table={table}
        tableCols={CHALLENGE_SUGGESTION_COLUMNS}
        tableData={dataFiltered}
        filters = {filters}
        resetFilters = {resetFilters}
        denseHeight={denseHeight}
        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
        notFound={notFound}
        handleFilters={handleFilters}
        handleResetFilters={handleResetFilters}
        // handleAddRow={handleAddRow}
        handleEditRow={handleEditRow}
        handleDeleteRow={handleDeleteRow}
        handleChangeState = {handleChangeState}
        onSelectRow={handleSelectRow} />
    </div>
  );
};

export default ChallengeSuggestion;