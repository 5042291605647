import * as Yup from 'yup';

export const getQuestionSchema = (isUpdate: boolean) => {
  const coverImageValidation = isUpdate ? Yup.mixed<any>().nullable() : Yup.mixed<any>().nullable().required('Question Image is required');

  return Yup.object().shape({
    questionText: Yup.string().required('Question Text is required'),
    questionImage: coverImageValidation,
    questionStep: Yup.number().required('Question Step is required'),
    answers: Yup.array(),
  });
};