import React, { useCallback, useState ,useEffect } from 'react';

import isEqual from 'lodash/isEqual';

import RoundedButton from '../../../components/common/button/rounded-button';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';
import { 
  useTable,  
  emptyRows,
  getComparator,
} from '../../../components/minimal/table';

// import { IStepItem, IStepTableFilters, IStepTableFilterValue } from '../../../types/step';
// import {STEP_COLUMNS, STEP_DUMMY_DATA} from '../../../consts/step';
import { IStepTableFilters, IStepTableFilterValue } from '../../../types/step';
import { STEP_COLUMNS } from '../../../consts/step';

import DataTable from '../../../components/common/data-table/data-table';

import { applyFilter } from '../../../utils/data-table-filter';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { HOST_API } from '../../../config-global';
// import handleAxiosErrors from '../../../interceptors/axios-errors';
import { handleErrors } from '../../../handlers/axiosErrorsHandler';
import { getStorage } from '../../../hooks/use-local-storage';
import { routes } from '../../../routes/routes';

// import { setAuthenticatedStatus } from '../../../interceptors/auth';
import { useAuth } from '../../../hooks/useAuth';

import { useSnackbar } from '../../../components/minimal/snackbar';
import { QUESTION_COLUMNS } from '../../../consts/question';
import { IQuestionItem, IQuestionTableFilters, IQuestionTableFilterValue } from '../../../types/question';
import { applyNestedFilter } from '../../../utils/data-table-nested-filter';
import QuestionForm from './components/question-form';

interface StepProps {
}

// ----------------------------------------------------------------------

// const dummyData: IStepItem[] = STEP_DUMMY_Data;

const defaultFilters: IStepTableFilters = {
  title: '',
};

// ----------------------------------------------------------------------

const Step: React.FC<StepProps> = () => {
// use global Authenticated State to check its current value or to change its value
const { setAuthenticated } = useAuth();
// console.log('Authenticated: ', isAuthenticated);

// const token = localStorage.getItem('Token');
const token = getStorage('Token');

const { enqueueSnackbar } = useSnackbar();

const navigate = useNavigate(); // Hook for navigation
// const router = useRouter(); 

//Main Table (Award Steps)
const table = useTable();

const [tableData, setTableData] = useState([]);
// const [tableData, setTableData] = useState(dummyData);

const [filters, setFilters] = useState(defaultFilters);

const dataFiltered = applyFilter({
  inputData: tableData,
  comparator: getComparator(table.order, table.orderBy),
  filters,
});

const dataInPage = dataFiltered.slice(
  table.page * table.rowsPerPage,
  table.page * table.rowsPerPage + table.rowsPerPage
  );
  
//Nested Table (Question Table)
const table2 = useTable();

const nestedDefaultFilters: IQuestionTableFilters = {
  questionText: '',
};

const [nestedTableData, setNestedTableData] = useState([]);

const [nestedFilters, setNestedFilters] = useState(nestedDefaultFilters);

const nestedDataFiltered = applyNestedFilter({
  inputData: nestedTableData,
  comparator: getComparator(table2.order, table2.orderBy),
  nestedFilters,
});

useEffect(() => {
  const fetchData = async () => {
    try {
      await axios.get(`${HOST_API}/api/admin/award-steps`, {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'x-locale': 'en',
        }
      }).then(res =>{
        var response = res.data;
        setTableData(response.data.items);
        // setLoading(false);
      })
    } catch (error: any) {
      handleErrors(error, setAuthenticated, enqueueSnackbar);
    }
    // setLoading(false);
  };

  fetchData();
}, [token, setAuthenticated, enqueueSnackbar]); // Empty dependency array to run only once on mount

const denseHeight = table.dense ? 52 : 72;

const resetFilters = !isEqual(defaultFilters, filters);

const notFound = (!dataFiltered.length && resetFilters) || !dataFiltered.length;

const handleFilters = useCallback(
  (name: string, value: IStepTableFilterValue) => {
    table.onResetPage();
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  },
  [table]
);

const handleResetFilters = useCallback(() => {
  setFilters(defaultFilters);
}, []);

const handleAddRow = () =>{
  navigate(routes.stepsCreate);
}

const handleEditRow = useCallback(
  (id: string) => {
    navigate(routes.stepsEdit(id));
    // navigate(routes.stepsEdit);
  },
  [navigate]
  // (id: string) => {
  //   router.push(routes.stepsEdit(id));
  // },
  // [router]
);

const handleDeleteRow = useCallback(
  async (id: string) => {
    // Send delete request using Axios
    await axios.delete(`${HOST_API}/api/admin/award-steps/${id}`, {
      headers: {
        // 'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-locale': 'en',
      }
    })
    .then(res => {
      // Check if the request was successful
      if (res.status === 200) {
        // var response = res.data;
        // If successful, update the table data state
        const deleteRow = tableData.filter((row:any) => row.id !== id);
        setTableData(deleteRow);

        // Update any other relevant state or perform actions
        table.onUpdatePageDeleteRow(dataInPage.length);

        enqueueSnackbar('Delete success!', {
          // variant: 'success',
          // anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
      } else {
        // Handle any errors or display error messages
        console.error('Delete request failed');
      }
    })
    .catch(error => {
      // Handle any errors or display error messages
      // console.error('Delete request failed:', error);
      handleErrors(error, setAuthenticated, enqueueSnackbar);
    });
  },
  [dataInPage.length, table, tableData, token, setAuthenticated, enqueueSnackbar]
);

// const handleDeleteRows = useCallback(() => {
//   const deleteRows = tableData.filter((row:any) => !table.selected.includes(row.id));
//   setTableData(deleteRows);

//   table.onUpdatePageDeleteRows({
//     totalRows: tableData.length,
//     totalRowsInPage: dataInPage.length,
//     totalRowsFiltered: dataFiltered.length,
//   });
// }, [dataFiltered.length, dataInPage.length, table, tableData]);

const handleChangeState = useCallback(
  (id: string, state: boolean) => {
    axios.patch(`${HOST_API}/api/admin/award-steps/${id}/published`, {'published': !state}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-locale': 'en',
      },
    })
    .then(response => {
      // Handle success response
      // console.log('API call successful:', response.data);
      // Update the published state in tableData (in current row)
      const updatedTableData: any = tableData.map((row: any) => {
        if (row.id === id) {
          return {
            ...row,
            published: !state
          };
        }
        return row;
      });
      setTableData(updatedTableData); // Update tableData state with the updated data
    })
    .catch(error => {
      // Handle error
      // console.error('Error occurred during API call:', error);
      handleErrors(error, setAuthenticated, enqueueSnackbar);
    });
},[token, tableData, setAuthenticated, enqueueSnackbar]);

const nestedDenseHeight = table.dense ? 52 : 72;

const resetNestedFilters = !isEqual(nestedDefaultFilters, nestedFilters);

const nestedNotFound = (!nestedDataFiltered.length && resetNestedFilters) || !nestedDataFiltered.length;

const handleNestedFilters = useCallback(
  (name: string, value: IQuestionTableFilterValue) => {
    table2.onResetPage();
    setNestedFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  },
  [table2]
);

const handleResetNestedFilters = useCallback(() => {
  setNestedFilters(nestedDefaultFilters);
}, []);

  // State to hold selected rows
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [stepId, setStepId] = useState<number>(-1); 
  const [questionId, setQuestionId] = useState<string>('-1'); 

// Function to handle row selection
const handleSelectRow = useCallback(
  async (id: string, selectionMode: string)=>{
  // Check if the row is already selected
  const selectedIndex = selectedRows.indexOf(id);
  let newSelected:any = [];

  table.onSelectRow(id);
  if (selectedIndex === -1) {
    // If not selected, add to the selection
    newSelected = newSelected.concat(selectedRows, id);
  } else if (selectedIndex === 0) {
    // If first element is selected, remove it
    newSelected = newSelected.concat(selectedRows.slice(1));
  } else if (selectedIndex === selectedRows.length - 1) {
    // If last element is selected, remove it
    newSelected = newSelected.concat(selectedRows.slice(0, -1));
  } else if (selectedIndex > 0) {
    // If middle element is selected, remove it
    newSelected = newSelected.concat(
      selectedRows.slice(0, selectedIndex),
      selectedRows.slice(selectedIndex + 1)
    );
    
  }

  // Update the selected rows state
  setSelectedRows(newSelected);
  // Send delete request using Axios
  await getQuestions(id);
},
[]// if add selectedRows to this array will make checkbox multiple otherwise single selected
);

async function getQuestions(id: string){
  axios.get(`${HOST_API}/api/admin/award-steps/${id}`, {
    headers: {
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-locale': 'en',
    }
  })
  .then(res => {
    var response = res.data;
    setStepId(response.data.id);
    setNestedTableData(response.data.questions);
  })
  .catch(error => {
    // Handle any errors or display error messages
    // console.error('Delete request failed:', error);
    handleErrors(error, setAuthenticated, enqueueSnackbar);
  });
}

// const [isQuestionFormOpen, setQuestionFormOpen] = useState(false); // State to manage the visibility of QuestionForm

// State to manage the visibility of the edit dialog
const [isQuestionFormOpen, setQuestionFormOpen] = useState(false);
// State to hold the current question being edited
const [currentQuestion, setCurrentQuestion] = useState<IQuestionItem | undefined>(undefined);


const handleAddQuestion = () =>{
  setQuestionId('-1');
  setCurrentQuestion({id: -1, questionText: "", questionImage: undefined, questionImageUrl: "", questionStep: stepId, answers: []});
  setQuestionFormOpen(true); // Open the QuestionForm dialog when clicking on "New Question"
}

// const handleCloseQuestionForm = () => {
//   setQuestionFormOpen(false); // Close the QuestionForm dialog
// };

const handleEditQuestion = useCallback(
  (id: string, row: any) => {
    // Set the questionStep for the current question being edited
    row.questionStep = stepId;
    setQuestionId(id);
    
    // Iterate over the answers array and set the answerQuestion property to the id
    // row.answers.forEach((answer: any) => {
    //   answer.answerQuestion = id;
    // });
    
    // Set the current question being edited
    setCurrentQuestion(row);
    // Open the edit dialog
    setQuestionFormOpen(true);
  },
  [stepId]
);

// Function to handle closing the edit dialog
const handleCloseQuestionForm = async () => {
  // Reset the current edited question
  setCurrentQuestion(undefined);
  // Close the edit dialog
  setQuestionFormOpen(false);
  await getQuestions(stepId.toString());
};

const handleDeleteQuestion = useCallback(
  async (id: string) => {
    // Send delete request using Axios
    await axios.delete(`${HOST_API}/api/admin/award-step-questions/${id}`, {
      headers: {
        // 'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-locale': 'en',
      }
    })
    .then(res => {
      // Check if the request was successful
      if (res.status === 200) {
        // var response = res.data;
        // If successful, update the table data state
        const deleteRow = nestedTableData.filter((row:any) => row.id !== id);
        setNestedTableData(deleteRow);

        // Update any other relevant state or perform actions
        table2.onUpdatePageDeleteRow(dataInPage.length);

        enqueueSnackbar('Delete success!', {
          // variant: 'success',
          // anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
      } else {
        // Handle any errors or display error messages
        console.error('Delete request failed');
      }
    })
    .catch(error => {
      // Handle any errors or display error messages
      // console.error('Delete request failed:', error);
      handleErrors(error, setAuthenticated, enqueueSnackbar);
    });
  },
  [dataInPage.length, table2, nestedTableData, token, setAuthenticated, enqueueSnackbar]
);

// Function to handle row selection
// const handleSelectQuestion = useCallback(
//   async (id: string, selectionMode: string)=>{
//     // Check if the row is already selected
//   const selectedIndex = selectedRows.indexOf(id);
//   let newSelected:any = [];

//   table.onSelectRow(id);
//   if (selectedIndex === -1) {
//     // If not selected, add to the selection
//     newSelected = newSelected.concat(selectedRows, id);
//   } else if (selectedIndex === 0) {
//     // If first element is selected, remove it
//     newSelected = newSelected.concat(selectedRows.slice(1));
//   } else if (selectedIndex === selectedRows.length - 1) {
//     // If last element is selected, remove it
//     newSelected = newSelected.concat(selectedRows.slice(0, -1));
//   } else if (selectedIndex > 0) {
//     // If middle element is selected, remove it
//     newSelected = newSelected.concat(
//       selectedRows.slice(0, selectedIndex),
//       selectedRows.slice(selectedIndex + 1)
//     );
//   }

//   // Update the selected rows state
//   setSelectedRows(newSelected);
//   // Send delete request using Axios
//   // await getQuestions(id);
// },
// []// if add selectedRows to this array will make checkbox multiple otherwise single selected
// );

// Function to handle selecting all rows
// const handleSelectAllRows = (event: any) => {
//   if (event.target.checked) {
//     const newSelectedRows: any = tableData.map((row: any) => row.id);
//     setSelectedRows(newSelectedRows);
//     return;
//   }
//   setSelectedRows([]);
// };
  
  return (
    <div>
      {/* Bread Crumb + Create Button */}
      <div className='flex items-center justify-between pb-3 h-12'> 
        {/* <h2>Management / Step</h2> */}
        <CustomBreadcrumbs
          // heading="List"
          links={[
            { name: 'Management', href: routes.steps },
            { name: 'Step', href: routes.steps },
            // { name: 'List' },
          ]}
        />
        {/* className='bg-[#637381]' */}
        <RoundedButton label='New Step' backgroundColor='#637381' onClick={handleAddRow} icon='mingcute:add-line' />
      </div>

      {/* DataTable */}
      <DataTable
        tableName='Step'
        deleteTitle='Step'
        mainCol='title'
        table={table}
        tableCols={STEP_COLUMNS}
        tableData={dataFiltered}
        filters = {filters}
        resetFilters = {resetFilters}
        denseHeight={denseHeight}
        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
        notFound={notFound}
        handleFilters={handleFilters}
        handleResetFilters={handleResetFilters}
        // handleAddRow={handleAddRow}
        handleEditRow={handleEditRow}
        handleDeleteRow={handleDeleteRow}
        handleChangeState={handleChangeState}
        onSelectRow={handleSelectRow}
        // onSelectAllRows={handleSelectAllRows}
        selectionMode='single'
        hasSelections={true}/>

      <div className='flex items-center justify-end py-8 h-12'>
        {/* <RoundedButton label='New Question' backgroundColor='#637381' onClick={handleAddQuestion} icon='mingcute:add-line' /> */}
        <RoundedButton label='New Question' backgroundColor='#637381' 
          onClick={()=>{
            if(stepId !== -1){
              handleAddQuestion()
            }else{
              enqueueSnackbar(`Please select step before create question!!`, { variant: 'error' });
            }
          }} 
          icon='mingcute:add-line' />
      </div>

      <DataTable
        tableName='Question'
        deleteTitle='Question'
        mainCol='questionText'
        table={table2}
        tableCols={QUESTION_COLUMNS}
        tableData={nestedDataFiltered}
        filters = {nestedFilters}
        resetFilters = {resetNestedFilters}
        denseHeight={nestedDenseHeight}
        emptyRows={emptyRows(table2.page, table2.rowsPerPage, nestedTableData.length)}
        notFound={nestedNotFound}
        handleFilters={handleNestedFilters}
        handleResetFilters={handleResetNestedFilters}
        // handleAddRow={handleAddRow}
        handleEditRow={handleEditQuestion}
        handleDeleteRow={handleDeleteQuestion}
        handleChangeState={handleChangeState}
        // onSelectRow={handleSelectQuestion}
        selectionMode='single'
        hasSearch = {false}
        hasEdit = {false}
        hasView = {true}
        />

      {/* QuestionForm dialog */}
      {/* <QuestionForm
        open={isQuestionFormOpen} // Pass the state to manage the dialog's visibility
        onClose={handleCloseQuestionForm} // Pass the function to close the dialog
        currentQuestion={undefined} // Pass undefined when creating a new question
      /> */}

      {/* QuestionForm dialog for editing or creating questions */}
      <QuestionForm
        open={isQuestionFormOpen} // Pass the state to manage the edit dialog's visibility
        onClose={handleCloseQuestionForm} // Pass the function to close the edit dialog
        currentQuestion={currentQuestion} // Pass the current edited question
        questionId={questionId}
        />

      <div style={{marginTop: '2rem'}}></div>

    </div>
  );
};

export default Step;