import React, { useCallback, useState, useEffect } from 'react';

import isEqual from 'lodash/isEqual';

import RoundedButton from '../../../components/common/button/rounded-button';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';
import { 
  useTable,  
  emptyRows,
  getComparator,
} from '../../../components/minimal/table';

// import { IChallengeItem, IChallengeTableFilters, IChallengeTableFilterValue } from '../../../types/challenge';
// import {CHALLENGE_COLUMNS, CHALLENGE_DUMMY_DATA} from '../../../consts/challenge';
import { IChallengeTableFilters, IChallengeTableFilterValue } from '../../../types/challenge';
import {CHALLENGE_COLUMNS} from '../../../consts/challenge';

import DataTable from '../../../components/common/data-table/data-table';

import { applyFilter } from '../../../utils/data-table-filter';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { HOST_API } from '../../../config-global';
// import handleAxiosErrors from '../../../interceptors/axios-errors';
import { handleErrors } from '../../../handlers/axiosErrorsHandler';
import { getStorage } from '../../../hooks/use-local-storage';
import { routes } from '../../../routes/routes';

// import { setAuthenticatedStatus } from '../../../interceptors/auth';
import { useAuth } from '../../../hooks/useAuth';

import { useSnackbar } from '../../../components/minimal/snackbar';

// ----------------------------------------------------------------------

interface ChallengeProps {
}

// ----------------------------------------------------------------------

// const dummyData: IChallengeItem[] = CHALLENGE_DUMMY_Data;

const defaultFilters: IChallengeTableFilters = {
  title: '',
};

// ----------------------------------------------------------------------

const Challenge: React.FC<ChallengeProps> = () => {
  // use global Authenticated State to check its current value or to change its value
  const { setAuthenticated } = useAuth();
  // console.log('Authenticated: ', isAuthenticated);

  // const token = localStorage.getItem('Token');
  const token = getStorage('Token');

  const { enqueueSnackbar } = useSnackbar();

  const table = useTable();

  const navigate = useNavigate(); // Hook for navigation

  const [tableData, setTableData] = useState([]);
  // const [tableData, setTableData] = useState(dummyData);
  
  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get(`${HOST_API}/api/admin/challenges`, {
          headers: {
            // 'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'x-locale': 'en',
          }
        }).then(res =>{
          var response = res.data;
          setTableData(response.data.items);
          // setLoading(false);
        })
      } catch (error: any) {
        // console.error('Error fetching category data:', error);
        handleErrors(error, setAuthenticated, enqueueSnackbar);
        // setLoading(false);
      }
    };

    fetchData();
  }, [token, setAuthenticated, enqueueSnackbar]); // Empty dependency array to run only once on mount

  const denseHeight = table.dense ? 52 : 72;

  const resetFilters = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && resetFilters) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IChallengeTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleAddRow = () =>{
    navigate(routes.challengesCreate);
  }

  const handleEditRow = useCallback(
    (id: string) => {
      navigate(routes.challengesEdit(id));
    },
    [navigate]
  );

  const handleDeleteRow = useCallback(
    async (id: string) => {
      // Send delete request using Axios
      await axios.delete(`${HOST_API}/api/admin/challenges/${id}`, {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'x-locale': 'en',
        }
      })
      .then(res => {
        // Check if the request was successful
        if (res.status === 200) {
          // var response = res.data;
          // If successful, update the table data state
          const deleteRow = tableData.filter((row:any) => row.id !== id);
          setTableData(deleteRow);

          // Update any other relevant state or perform actions
          table.onUpdatePageDeleteRow(dataInPage.length);

          enqueueSnackbar('Delete success!', {
            // variant: 'success',
            // anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
          });
        } else {
          // Handle any errors or display error messages
          console.error('Delete request failed');
        }
      })
      .catch(error => {
        // Handle any errors or display error messages
        // console.error('Delete request failed:', error);
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      });
    },
    [dataInPage.length, table, tableData, token, setAuthenticated, enqueueSnackbar]
  );

  // const handleDeleteRows = useCallback(() => {
  //   const deleteRows = tableData.filter((row:any) => !table.selected.includes(row.id));
  //   setTableData(deleteRows);

  //   table.onUpdatePageDeleteRows({
  //     totalRows: tableData.length,
  //     totalRowsInPage: dataInPage.length,
  //     totalRowsFiltered: dataFiltered.length,
  //   });
  // }, [dataFiltered.length, dataInPage.length, table, tableData]);

  const handleChangeState = useCallback(
    (id: string, state: boolean) => {
      axios.patch(`${HOST_API}/api/admin/challenges/${id}/published`, {'published': !state}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'x-locale': 'en',
        },
      })
      .then(response => {
        // Handle success response
        // console.log('API call successful:', response.data);
        // Update the published state in tableData (in current row)
        const updatedTableData: any = tableData.map((row: any) => {
          if (row.id === id) {
            return {
              ...row,
              published: !state
            };
          }
          return row;
        });
        setTableData(updatedTableData); // Update tableData state with the updated data
      })
      .catch(error => {
        // Handle error
        // console.error('Error occurred during API call:', error);
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      });
  },[token, tableData, setAuthenticated, enqueueSnackbar]);

  // const handleApprovalState = useCallback(
  //   (id: string, approval: string) => {
  //     axios.patch(`${HOST_API}/api/admin/challenges/${id}/approveal`, {"approvalStatus": approval}, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${token}`,
  //         'x-locale': 'en',
  //       },
  //     })
  //     .then(response => {
  //       // Handle success response
  //       // console.log('API call successful:', response.data);
  //       if(response.data.data.approvalStatus.label === 'Rejected')
  //         enqueueSnackbar('Challenge ' + response.data.data.approvalStatus.label, {variant: 'error'});
  //       else
  //         enqueueSnackbar('Challenge ' + response.data.data.approvalStatus.label);
  //     })
  //     .catch(error => {
  //       // Handle error
  //       // console.error('Error occurred during API call:', error);
  //       handleErrors(error, setAuthenticated, enqueueSnackbar);
  //     });
  // },[token, setAuthenticated, enqueueSnackbar]);

  // State to hold selected rows
  const [selectedRows, setSelectedRows] = useState<any>([]);

  // Function to handle row selection
  const handleSelectRow = useCallback((id: string, selectionMode: string)=>{
    // Check if the row is already selected
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected:any = [];

    table.onSelectRow(id);
    if (selectedIndex === -1) {
      // If not selected, add to the selection
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      // If first element is selected, remove it
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      // If last element is selected, remove it
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      // If middle element is selected, remove it
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
      
    }

    // Update the selected rows state
    setSelectedRows(newSelected);
  },
  [table, selectedRows]
  );

  return (
    <div>
      {/* Bread Crumb + Create Button */}
      <div className='flex items-center justify-between pb-3 h-12'>
        {/* <h2>Management / Challenges</h2> */}
        <CustomBreadcrumbs
          // heading="List"
          links={[
            { name: 'Management', href: routes.challenges },
            { name: 'Challenges', href: routes.challenges },
            // { name: 'List' },
          ]}
        />
        {/* className='bg-[#637381]' */}
        <RoundedButton label='New Challenge' backgroundColor='#637381' onClick={handleAddRow} icon='mingcute:add-line' />
      </div>
      
      {/* DataTable */}
       <DataTable
        tableName='Challenges'
        deleteTitle='Challenge'
        mainCol='title'
        table={table}
        tableCols={CHALLENGE_COLUMNS}
        tableData={dataFiltered}
        filters = {filters}
        resetFilters = {resetFilters}
        denseHeight={denseHeight}
        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
        notFound={notFound}
        handleFilters={handleFilters}
        handleResetFilters={handleResetFilters}
        // handleAddRow={handleAddRow}
        handleEditRow={handleEditRow}
        handleDeleteRow={handleDeleteRow}
        handleChangeState = {handleChangeState}
        // handleApprovalState={handleApprovalState}
        onSelectRow={handleSelectRow}
        />
    </div>
  );
};

export default Challenge;